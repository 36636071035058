@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
.EMP-container {
  /* background: url("./images/bg.jpg"); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  /* overflow-y: scroll; */
}

.EMP-hero {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
  /* margin-left: 40px; */
  /* margin-right: 40px; */
}

.events_back {
  display: flex;
  width: 100%;
  height: 75vh;
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  justify-content: center;
  margin-top: 5vh;
  padding: 14px 0;

}
.eventwrappe{
  width:92vw;
  /* height:50vw; */
  /* margin-top:15vh; */
  display: flex;
  backdrop-filter: blur(5px);
  /* border: 2px solid black; */
  border-radius: 1.3vw;
  background-color: #c4dffb;
  filter: drop-shadow(2px 4px 6px black);
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); Add this line */
}.eventwrappe1{
  /* width:56vw; */
  /* height:50vw; */
  height: 60vh;
  margin-top:15vh;
  display: flex;
  backdrop-filter: blur(5px);
  /* border: 2px solid black; */
  border-radius: 1.3vw;
  background-color: #c4dffb;
  filter: drop-shadow(2px 4px 6px black);
}

.events_back1 {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin-top: -11vh;
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  backdrop-filter: blur(7px);

}

/* .events_back::-webkit- {
  display: none;
}
.events_back1::-webkit-scrollbar {
  display: none;
} */


.events-left {
  width: 60vw;
  padding-left: 7vw;
  gap: 5%;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}
.events-left1 {
  width: 60vw;
  /* padding-left: 7vw; */
  gap: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* overflow-y: scroll; */
}

.events-left::-webkit-scrollbar {
  display: none;
}

.events-right {
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 5vw;
}

.event-photo {
  width: 30vw;
  height: 60vh;
  margin-right: 2px;
  border-radius: 1.5vw;
  filter: drop-shadow(2px 4px 6px black);
  /* border: 2px solid #fff; */
}


.events-left-event01 {
  color: black;
  font-family: Salsa;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 5vh 0 0 0 ;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.events-left-event1 {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 60px;
}

.events-left-event1 h1 {
  color: black;
  font-family: Salsa;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 16px; */
  /* 33.333% */
}

.events-left-event1 span {
  color: black;
  margin-top: 8px;
  font-family: Salsa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 100% */
  letter-spacing: -0.32px;
}

.events-left-event2 {
  color: black;
  font-family: Salsa;
  font-size: 14px;
  width:48vw;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: 150%;
  text-align: justify   ;
  /* 21px */
  letter-spacing: -0.28px;
}

.events-left-event3 {
  display: flex;
  gap: 5px;
  display: none;
}

.events-left-event3 span {
  color: black;
  font-family: Salsa;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.32px;
}

.events-left-event3 p {
  color: black;
  font-family: Salsa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  letter-spacing: -0.28px;
  margin-top: 2px;
}

.events-left-event4 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin:  0;
}

.events-left-event4 span {
  color: black;
  font-family: Salsa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  /* 164.286% */
  letter-spacing: -0.28px;
}

.events-left-event4 h1 {
  color: black;
  font-family: Salsa;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 33.333% */
  letter-spacing: -0.96px;
}

.Event-MObile-View {
  display: none;
}

.events-left-event5 {
  display: flex;
  margin: 0 0 10px 0;
  gap: 30px;
}

.events-left-event5-btn1 {
  display: inline-flex;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  text-align: center;
  /* leading-trim: both; */
  /* text-edge: cap; */
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Salsa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  height:5vh;
  /* 24px */
  border-radius: 8px;
  background: #33576c;
  letter-spacing: 0.4px;
  cursor: pointer;
  border:2px solid white;
}

#newchangesinbutton {
  padding: 15px 30px !important;
}

.events-left-event5-btn2 {
  display: inline-flex;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.60);
  color: black;
  text-align: center;
  /* leading-trim: both; */
  /* text-edge: cap; */
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Salsa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  height:4.2vh;
  border:2px solid black;
  /* 24px */
  background: transparent;
  letter-spacing: 0.4px;
  cursor: pointer;
  text-decoration: none;
}

.events-left-event6 {
  color: #FFF;
  font-family: Salsa;
  font-size: 33.486px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.67px;
  /* margin-top: 15%; */
  padding-bottom: 2%;
  gap: 24vw;
}

.events-left-event7 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.events-left-event7 input {
  display: flex;
  width: 400px;
  height: 37.848px;
  padding: 11.259px 18.765px;
  justify-content: center;
  align-items: center;
  gap: 9.382px;
  border-radius: 5.592px;
  border: 1.398px solid #505050;
  background: #FFF;
  /* box-shadow: 1.3980079889297485px 2.796015977859497px 0px 0px #505050; */
  flex-shrink: 0;
  color: #505050;
  font-family: Salsa;
  font-size: 16.057px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.012px;
  /* 93.493% */
  flex: 1 0 0;
}

.events-left-event6 {
  padding-bottom: 30px;
  display: flex;
  gap: 200px;
  filter: brightness(0);
}

.events-left-event8 {
  display: flex;
  width: 149px;
  height: 42px;
  padding: 6.261px 47.403px;
  cursor: pointer;
  margin-top: 40px;

  justify-content: center;
  align-items: center;
  gap: 8.944px;
  flex-shrink: 0;
  border-radius: 7.155px;
  border: 1.789px solid #111;
  background: rgba(247, 185, 0, 0.60);
  box-shadow: 1.7887980937957764px 3.5775961875915527px 0px 0px #111;
  color: #FFF;
  font-family: Salsa;
  font-size: 25.043px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.events-left-event9 {
  display: flex;
  margin-bottom: 2%;
  gap: 3%;
  display: flex;
  flex-wrap: wrap;
}

.events-left-event9 span {
  color: #FFF;
  font-family: Salsa;
  font-size: 18.35px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.35px;
  /* 100% */
  letter-spacing: -0.367px;
}

.events-left-event9 div {
  gap: 20px;
  display: flex;
}

.events-left-event9 input {
  border-radius: 10vh;
  width: 18.35px;
  height: 18.35px;
  flex-shrink: 0;
}


.bg-events {
  position: absolute;
  height:100vh;
  bottom: 0px;
  width: 100vw;
  background-size: cover;
  background-position: center center;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(255, 255, 255, 0.60); */

}


.nav-events {
  height: 3px;
  position: relative;
  top: 37px;
  width: 76px;
  right: 10px;
  background: #F7B900;
}


#cross_img_event {
  height: 22px;
  margin-top: 5px;
  background-color: white;
  width: 50px;
  height: 50px;

}

.checkbox {
  transform: scale(2);
  margin-right: 30px;
  margin-left: 20px;
}

@media (max-width:1500px) {
  .events-left-event1 h1 {
    font-size: 3.3vw;
  }
}

@media (max-width:1500px) {
  .events-left-event1 h1 {
    font-size: 3.3vw;
  }
}

@media (max-width:1200px) {
  .events-left-event6 {
    gap: 15vw;
  }
}

@media (min-width:601px) {
  .events-left {
    overflow-y: scroll;
  }
}

@media (max-width:768px) {
  .events_back {
    flex-direction: column-reverse;
    align-items: center;
    width: 100vw;
    height: auto;
    position: absolute;
    margin-top: -2vh;
    /* overflow-y: scroll; */
    scroll-behavior: smooth;
    padding-bottom: 50px;
    position: relative;
    z-index: 0;
    overflow-x: hidden;
  }
  .eventwrappe{
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
  }

  .events_back1 {
    /* flex-direction: column-reverse; */
    align-items: center;
    width: 100vw;
    height: 90vh;
    position: fixed;
    margin-top: -2vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .bg-events {
    display: none;
  }

  #newchangesinbutton {
    padding: 15px 21px !important
  }



  /* .events-left-event5-btn2 {
    padding: 15px 21px !important;
  } */

  .events-left {
    width: 100vw;
    /* height: 55%; */
    gap: 11px;
    margin-top: 5vh;
    /* overflow-y:auto; */
    /* overflow-x: hidden; */
    /* overflow-y:  auto !important; */
  }

  .events-right {
    width: 80vw;
    height: 50%;
    justify-content: flex-start;
    margin-top: 5vh;
  }

  .event-photo {
    justify-content: center;
    width: 90vw;
    height: 32vh;
  }

  /* .events-left-event5-btn2,
  .events-left-event5-btn2 {
    /* height: 40px !important; */
  .event-photo {
    display: none;
  }

  .event-photo1 {
    display: block !important;
    width: 70vw;
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 10px;
    /* height: 30vh; */
  }

  .events-left-event2 {
    color: black;
    font-family: Salsa;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    letter-spacing: -0.24px;
  }

  .events-left-event3 {
    display: none;
  }

  .events-left-event5 {
    margin: 10px 0;
  }

  .events-left-event5 {
    gap: 15px;
  }

  .events-left-event9 span {
    font-size: 4vw;
    width: 330px;
  }

  .events-left-event9 div {
    flex-direction: row-reverse;
    width: 69%;
  }

  .events-left-event9 {
    flex-direction: column;
    gap: 13px;
    margin-top: 10px;
  }

  .events-left1 {
    width:80vw;
    margin-bottom: 1.5vh;
  }

  .events-left2 {
    margin-bottom: 16vh;
  }

  .events-left1 input {
    width: 88vw;
  }

  .events-left2 input {
    width: 88vw;
  }

  .events-left-event6 {
    padding-bottom: 20px;
    display: flex;
    /* gap: 27vw; */
  }

  .events-left-event6 {
    margin-top: 3%;
  }

  .event-cross {
    position: absolute;
    /* top: 45.5vh !important; */
    /* left: 78vw !important; */
    cursor: pointer;
    width: 6vw;
    margin-left: -8vw;
    margin-top: -0.5vh;
  }

  .events-right {
    display: flex;
    flex-direction: column;
  }

  .events-left-event01 {
    display: none !important;
  }

  .events-left-event {
    display: block !important;
    color: black;
    font-family: Salsa;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.24px;
    width: 100%;
    margin: 3vh 0;
  }

  .events-left-event1 {
    margin: 0px 0 0 0;
  }

  .events-left-event1 h1 {
    color: black;
    font-family: Salsa;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 100%; */
    /* 40px */
    letter-spacing: -0.8px;
  }

  .events-left-event2 {
    color: black;
    font-family: Salsa;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: justify;
    /* 18px */
    letter-spacing: -0.24px;
    margin: 0px 0;
    width: 78vw;
  }

  .events-left-event4 {

    margin: 0px 0;
  }

  .events-left-event4 h1 {
    color: black;
    font-family: Salsa;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    /* 110% */
    letter-spacing: -0.8px;
  }

  .events-left-event7 input {
    width: 50vw;
  }

  .events-left-event4 span {
    color: black;
    font-family: Salsa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.32px;
  }

  .checkbox {
    transform: scale(3);
    margin: 20px;
  }
}

.events-left-event {
  display: none;
}

.event-cross {
  position: relative;
  cursor: pointer;
  width: 50px;
}

.event-photo1 {
  display: none;
  margin-top: 40px;
}

@media screen and (max-width:768px) {
  .Event-MObile-View {
    display: contents;
  }

  .EMP-container {
    display: none;
  }



  .eventcard-nav {
    display: flex !important;
    color: #FFF !important;
    font-family: Salsa !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    height: 10vh !important;
    justify-content: center !important;
    width: 100% !important;
    align-items: center !important;
    gap: 18% !important;
  }

  .eventcards {
    display: flex !important;
    gap: 6vw !important;
    overflow-y: auto !important;
    justify-content: center !important;
    margin: 3vh 0 !important;
    height: 68vh !important;
    ;
    flex-wrap: wrap !important;
  }
}
@media (min-width:601px) {
  .events-left {
    overflow-y: scroll;
  }
}

@media screen and (max-width:600px) {
  .events-left-event1 h1 {
    color: black;
    font-family: Salsa;
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16px; */
    /* 33.333% */
  }
}