.event-mob-page-container {
  background:transparent;
  color: white;
  display: flex;
    justify-content: center;
}
.all-dropdown-flex {
  display: flex;
  height: 4vh;
  flex-direction: column;
}
.all-dropdown {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 60vh;
  overflow-y: scroll;
  background-color: white;
  color: black;
  position: absolute;
  z-index: 2;
  margin-top: 54px;
  width: 148px;
}
