@import url('https://fonts.googleapis.com/css?family=Saira:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic');
.HomeMUN-body{
    /* background:#0b1d39; */
    /* position: relative; */
    width:100vw;
    /* height:700vh; */
    overflow-x: hidden; 
    position: absolute;
    z-index: -5;
    background-color: #7e91ae;
    
    /* background-image: url("../../../assests/MUNmobileBG.webp"); */
}
.HomeMUN-bg
{
    /* background-color: #0b1d39; */
    position:absolute;
    z-index: -1;
    width: 100vw;
    /* height: 700vh; */
    display: flex;
    flex-direction: column;
    object-fit:cover;
}

.HomeMUN-bg-1 {
    height: 100vh;
    width: 100vw;
    position: relative;
}
.HomeMUN-bg-2 {
    height: auto;
    width: 100vw;
    position: relative;
}

.top-HomeMUN
{
    display: flex;
    justify-content: center;
    margin-top: 6vh;
}
.top-HomeMUN-img
{
    width: 50vw;
}
.registerMUNNowBtn
{
    display: flex;
    justify-content: center;
}
.registerMUNNowBtnClick
{
    /* width: 20vw; */
    border-radius: 16px;
    color: #000;
    font-family: Saira;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 32px */
    letter-spacing: 1.6px;
    padding: 1vh 2.5vw;
    background-color: #fff;
    margin-top: 25vw;
}
.registerMUNNowBtnClick:hover
{
    background-color: #FDD52E;
}
.prizesMUNWorth {
    border-radius: 14px;
    /* background: rgba(8, 48, 114, 0.40); */
    border:5px solid #FDD52E;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 32.44px */
    letter-spacing: 1px;
    width: 75vw;
    margin: auto;
    margin-top: 20vh;
    padding: 1.5vh 2vw;
    padding-bottom: 2.5vh;
}


.prizesMUNWorth1 {
    width: 55vw;
    display: block;
    margin-bottom: 5vh;
}

.prizesMUNWorthDiv {
    display: flex;
    justify-content: center;
}

.committee_sec{
    background-color:rgba(0, 17, 46, 0.40);
    box-shadow: 6px 6px 4px 0px #00000024 inset;
    border-radius:10px;

}
.CommitteeHead
{
    margin-top: 10vh;
    display: flex;
    justify-content: center;
}
.CommitteeHead img{
    width: 40vw;
}
#committeeHead1 img{
    margin-top:10vh;
    margin-bottom: -2vh;
}
#committeeHead2 img{
    margin-top:18vh;
    width:55vw;
}


/* @media only screen and (max-width:680px)
{
    .HomeMUN-bg
{
    height: 743vh;
}
} */



/* @media only screen and (max-width:550px)
{
    .prizesMUNWorth
    {
        margin-top: 10vh;
    }           
} */
/* @media only screen and (min-height:600px){
    .HomeMUN-bg{
        height:685vh;
    }
} */


.ExecutiveMobile
{
    display: flex;
    justify-content: center;
}

{
@media only screen and (max-width:768px)
{
    /* .FAQimg
    {
        width: 15vw;
    }
    .HomeMUN-bg{
        height:840vh;
    } */
}
/* @media only screen and (max-height:740px)
{
    .HomeMUN-bg
{
    height: 800vh;
}
} */
@media only screen and (max-width:700px)
{
    /* .HomeMUN-bg
{
    height: 832vh;
} */
}

@media only screen and (max-width:680px)
{
    /* .HomeMUN-bg
    {
        height: 832vh;
    } */
}

@media only screen and (max-width:640px)
{
    /* .HomeMUN-bg
    {
        height: 670vh;
    }
    .HomeMUN-body{
        height: 670vh;
    } */
}
@media only screen and (max-height:601px)
{
    /* .HomeMUN-bg
    {
        height: 841vh;
    } */
}
@media only screen and (max-width:600px)
{
    /* .HomeMUN-bg
{
    height: 808vh;
}
.FAQimg
    {
        width: 20vw;
    } */
}

@media only screen and (max-width:550px)
{
    /* .prizesMUNWorth
    {
        margin-top: 20vh;
    } */
}
@media only screen and (max-width:549px)
{
    /* .HomeMUN-bg
{
    height: 804vh;
} */
}
}

@media only screen and (max-width:500px)
{
    .top-HomeMUN-img {
        margin-top: 3vh;
    }
    .prizesMUNWorth{
        margin-top: 18vh;
    }
}
@media only screen and (max-width:450px)
{
    .top-HomeMUN-img {
        margin-top: 4vh;
    }
    .prizesMUNWorth{
        margin-top: 19vh;
    }
    .CommitteeHead {
        margin-top: 7vh;
    }
    /* #committeeHead2{

    } */
}
@media only screen and (max-width:400px)
{
    .top-HomeMUN-img {
        margin-top: 4vh;
    }
    .prizesMUNWorth{
        margin-top: 21vh;
    }
    .CommitteeHead {
        margin-top: 5vh;
    }

}
@media only screen and (max-width:350px)

{
    .top-HomeMUN-img {
        margin-top: 5vh;
    }
    .prizesMUNWorth{
        margin-top: 22vh;
    }
    .CommitteeHead {
        margin-top: 5vh;
    }
}
/* @media only screen and (max-width:349px)

{
    .HomeMUN-bg
{
    height: 552vh;
}
} */
/* @media only screen and (max-width:330px){
    .HomeMUN-bg{
        height: 755vh;
    }
} */