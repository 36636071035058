body {
    background: #000000;
}

#wel_log_back {
    margin-top: 13vh;
    width: 42vw;
  }

#mobilewp {
  display: none;
}
  
  #campus_ambd {
    margin-top: -2vh;
    margin-left: 11vw;
    width: 23vw;
  }
  .fp_maindiv {
    margin: 10vh 0 0 0;
    height: 80vh;
  }
  
  .forgot {
    display: flex;
    flex-direction: column;
    align-items: center;
    background:transparent;
  
    margin: 0;
    width: fit-content;
    
    height: auto;
   
    border: 2px solid white;
    border-radius: 5px;
    /* box-shadow: 12px 12px 0px 0px #00000066; */
    padding: 0 6.5vw;
    backdrop-filter: blur(2px);

  

    .f-h2 {
      font-family: Public Sans;
      color: yellow;
      font-size: 35px;
      font-weight: 900;
      line-height: 32px;
      text-align: center;
      margin-top: 8vh;
    }

    .f-pa {
      width: 76% !important;
      text-align: center;
     
      margin: 5vh auto auto auto;
      font-size: 16px;
      color: white;
    }
  }
  

  .send {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
    font-family: Public Sans;
    font-weight: 400;
    background-color: yellow;
    color: #ffffff;
    margin: 5vh auto;
    border: 1.52px solid #505050;
    box-shadow: 1.518475890159607px 3.036951780319214px 0px 0px #505050;
    cursor: pointer;
  }
  
  .send:hover {
    background-color: yellow;
  }
  
  .email {
    width: 25vw;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
    font-family: Public Sans;
    font-weight: 400;
    margin: 6vh auto 0 auto;
    /* box-shadow: 2px 2px #000000; */
    background-color: transparent;
    border: 2px solid white;
    color: white;
    text-align: left;
    padding-left: 10px;
  }
  .email::placeholder{
    color: white;
    padding-left: 10px;
  }
  
  .message {
  
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 60%;
    height: 6rem;
    margin: 0 auto 0 auto;
  }
  .newBg {
    margin: 10vh 0 0 0;
    height: 89.8vh !important;
  }
  

.nav {
    width: 100vw;
}

#log_bg1 {
    position: absolute;
    z-index: 999;
}
  
#welcomebckbg {
  z-index: -1;
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0vh;
}

#mobilewp {
  z-index: -1;
  width: 100vw !important;
  height: 100vh !important;
}

#bg {
  .forget-a {
    width: 100vw;
  }
}

.logo-for {
  position: absolute;
  width: 100px;
    margin: 16px 34px;
    cursor: pointer;
}

  
  @media only screen and (max-width: 1079px) {
    .forgot {

      width: 50vw !important;
    }

    .email {
      width: 35vw;
    }
  }
  @media only screen and (max-width: 800px) {
    .fp_maindiv {
      margin: 0;
    }
    .logo-for {
      width: 60px;
    }

    .forgot {
      margin: 25vh 0 0;
    }

    /* #welcomebckbg {
      display: none;
    } */
    

    #mobilewp {
      display: block;
      width:100vw;
      height:100vh;
      position:absolute;
      z-index: -1;
    }

    .email {
      width: 40vw;
    }

    .send {
      width: 40vw;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .forgot {
      width: 70vw !important;
    }

    

    .email {
      width: 45vw;
    }

    .send {
      width: 45vw;
    }
  }
  
  @media only screen and (max-width: 460px) {
    .forgot {
      width: 80vw !important;
  
    }

    .email {
      width: 60vw;
    }

    .send {
      width: 60vw;
    }
  }
