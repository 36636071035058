.id_border1 {
    /* margin-vertical 17px; */
    /* height: 45vh; */
    width: 30vw;
    border-radius: 3px;
    padding: 3px;
    background-color: white;
  }

  .id_border2 {
    /* height: 100%; */
    /* width: 100%; */
    border: 1px dashed black;
    padding: 5px;
  }
  .id_box {
    /* height: 100%; */
    /* width: 100%; */
    border: 1px solid black;
    padding: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .xyz{
    font-size:1.5rem;
    margin-bottom: 5vh;
    color:white;
  }

  .id_box_left {
    /* height: 100%; */
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2vw;
  }

  .id_card_full_box{
    height:78vh;
  }

  .id_box_right {
    /* height: 100%; */
    width: 60%;
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
  }
  .id_box_qr_text{
    font-size: 1vw;
  }

  .id_box_image {
    height: 11vw;
    width: 100%;
    border: 1px solid black;
    padding: 1px;
  }

  .id_box_qr {
    height: 9vw;
    color:black;
    width: 100%;
    border: 2px solid black;
    padding: 2px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  #myqr{
    width: 100% !important;
    height: 9vw !important;
  }

  .id_box_id {
    font-size: 1.3vw;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .id_box_text1 {
    font-size: 0.9vw;
    margin-top: 1vh;
  }

  .id_box_text2 {
    font-size: 1vw;
    font-weight: bold;
    margin-top: 2px;
  }

  .id_box_textB {
    font-size: 1vw;
    font-weight: bold;
    margin-top: 1vh;
  }

  .id_box_text_split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .id_bottom {
    height: 41px;
    width: 100%;
    bottom: 0px;
    background-color: #F7B401;
  }
  /* .right_id_card
  {
    margin-left: 3vw;
  } */
  .generate
  {
    /* height: 6vh; */
    /* background-color: white; */
    color: black;
    margin-top: 4vh;
    /* margin-bottom: 4vh; */
    padding: 1vw 2vh;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7b900;
    font-weight: 550;
    border-color: #f7b900;
  }
  .generate:hover{
    background: transparent;
    color: #f7b900;
  }
  .not-download
  {
    /* height: 6vh; */
    background-color: rgb(149, 145, 145);
    color: black;
    margin-top: 4vh;
    /* margin-bottom: 4vh; */
    padding: 1vw 2vh;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .generate1
  {
    /* height: 6vh; */
    /* background-color: white; */
    color: black;
    margin-top: 4vh;
    margin-bottom: 4vh;
    padding: 1vw 2vh;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7b900;
    font-weight: 550;
    border-color: #f7b900;
  }
  .generate1:hover{
    background: transparent;
    color: #f7b900;
  }
  .generate:hover .downloadsign{
    color: #f7b900;
  }
  .id_card_uploadphoto_text
  {
    margin-bottom: 5vh;
    font-size: 1.3vw;
    width: 30vw;
  }
  .id_card_downloadidcard_text
  {
    margin-top: 4vh;
    font-size: 2vw;
  }
  .downloadsign, .done-img
  {
    height: 12px;
    width: 12px;
  }
  /* .downloadsign:hover{
    color: #f7b900;
  } */

  .preview
  {
    color: white;
    margin-top: 3vh;
  }

  @media only screen and (max-width:900px){
    .id_box_text2{
      font-size: 0.9vw;
    }
  }

  @media screen and (max-width:768px)
  {
    .id_border1
    {
      width: auto;
      margin-top: 5vh;
    }
    .id_box_qr_text
    {
      text-align: center;
      font-size: 10px;
    }
    .id_card_uploadphoto_text
    {
      color: white;
      font-size: 12px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .right_id_card
    {
      margin-top: 3vh;
      width: 80%;
      margin-left: 0vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .generate1{
      margin: 4vh auto;
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 25vw; */
      font-size: 20px;
    }
    .generate, .not-download{
      margin: 4vh auto;
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 25vw; */
      font-size: 20px;
    }
    .id_card_downloadidcard_text{
      color: #fff;
      font-size: 12px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .xyz{
      margin-bottom:0px !important;
      font-size:1.2rem !important;
    }
    .done-img{
      width: 20px;
      height: 20px;
    }
    .downloadsign{
      width: 20px;
      height: 20px;
    }
    .id_box_image{
      height: 80px;
      width: 80px;
    }
    .id_box_qr{
      height: 80px;
      width: 80px;
    }
    .id_box_id{
      font-size: 15px;
    }
    .id_box_text1{
      font-size: 10px;
    }
    .id_box_text2{
      font-size: 10px;
    }
    .id_box_textB{
      font-size: 11px;
    }
    #myqr{
      width: 80px !important;
      height: 80px !important;
    }
  }

  @media only screen and (max-width:500px){
    /* .id_border1{
      margin-top: 20vh;
    } */
    .generate, .generate1, .not-download{
      font-size: 18px;
    }
    .downloadsign, .done-img{
      width: 18px;
      height: 18px;
    }
  }
  @media only screen and (max-width:360px){
    .id_border1{
      width: 90%;
    }
    .id_box_text2{
      font-size: 9px;
    }
    .generate, .generate1, .not-download{
      font-size: 15px;
    }
    .downloadsign, .done-img{
      width: 15px;
      height: 15px;
    }
    
  }