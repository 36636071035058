@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
@keyframes imgRotate{
    0%{
        /* transform: rotateZ(45deg); */
        scale:1;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
    50%{
        /* transform: rotateZ(0); */
        scale:1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    100%{
        /* transform: rotateZ(0); */
        scale:1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
@keyframes popUp{
    0%{
        transform: translateY(14vw);
        opacity: 0;
        background: rgb(187, 185, 185);

    }
    30%{
        transform: translateY(14vw);
        opacity: 0;
        background: rgb(187, 185, 185);

    }
    60%{
        background: rgb(187, 185, 185);
    }
  
    100%{
        transform: translateY(0);
        opacity: 1;
        background: transparent;
       
    }
}
@keyframes popUpMob{
    0%{
        transform: translateY(35vw);
        opacity: 0;
        background: rgb(187, 185, 185);

    }
    30%{
        transform: translateY(35vw);
        opacity: 0;
        background: rgb(187, 185, 185);

    }
    60%{
        background: rgb(187, 185, 185);
    }
  
    100%{
        transform: translateY(0vw);
        opacity: 1;
        background: transparent;
       
    }
}
.new_ev_main{
    width:19vw;
    height:22vw;
    user-select: none;
    background-image: url(../../../../assets/eventcardbg.webp);
    background-size: cover;

}

.new_ev_main:hover{
    cursor: pointer;
}
.newcdbg{
    width:19vw;
    position: absolute;
    /* z-index: -1; */
}
.newcdwrap{
    display: flex;
    flex-direction: column;
    width:19vw;
    /* justify-content:flex-end; */
    align-items: center;
    gap:1vw;
    overflow: hidden;
    height:22vw;
    /* margin-top:3vh; */

}
.newimgsec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    width:14vw;
    margin-top: 2vw;
    border-radius: 10px;
    
    /* overflow: hidden; */
}
.newimg{
    width:14vw;
    aspect-ratio: 1;
    /* transform: rotateZ(45deg); */
    scale:1;
    border-radius: 10px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
/* .newimg:hover{
    animation: imgRotate 1s forwards;
} */
.new_ev_main:hover .newimg {
    animation: imgRotate 1s forwards;
}
.newcdtitle{
    font-family:Salsa;
    font-size: 1vw;
    z-index: 1;
    padding-bottom: 20px;
    text-align: center;
    width:15vw;
    
}
.hidwrap{
    /* position: absolute; */
    width:14vw;
    height:14vw;
    margin-top: -14vw;
    overflow: hidden;
}
.newhiddensec{
    /* position: absolute; */
    width: 14vw;
    height:14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:3px;
    backdrop-filter: blur(5px);
    background: rgb(0 0 0 0.5);
    border-radius: 10px;
    opacity: 0;
    /* border:2px solid red; */
    
}
.newhiddensec p{
    width:12vw;
    font-family: Salsa;
    text-align:center;
    color: white;
    font-size: 1vw;
    
}
.new_ev_main:hover .newhiddensec {
    animation: popUp 1.5s forwards;
}
.newcdbutton{
    background: transparent;
    border:2px solid #f29f42;
    color:#f29f42;
    padding:3px 5px 3px 5px;
    border-radius: 4px;
    font-family: Salsa;
    margin-top: 2vw;
    font-size: 1.2vw;
}
@media only screen and (max-width:768px){
    .new_ev_main{
        width:50vw;
        height:58vw;
    }
    .newcdwrap{
        width:50vw;
        height:58vw;
        gap:7vw;
        justify-content: flex-end;
    }
    .newimgsec{
        width:35vw;
        height:35vw;
    }
    .newimg{
        width:35vw;
    }
    .hidwrap{
        width:35vw;
        height:35vw;
        margin-top: -35vw;
    }
    .newhiddensec{
        width:35vw;
        height:35vw;
        /* opacity: 1; */
    }
    .newcdtitle{
        font-size: 2vw;
        width: 44vw;
    text-align: center;
    }
    .new_ev_main:hover .newhiddensec {
        animation: popUpMob 1.5s forwards;
    }
    .newhiddensec p{
        font-size: 2.6vw;
        width:25vw;
    }
    .newcdbutton{
        font-size: 3vw;
    }
}