@import url("https://fonts.googleapis.com/css2?family=Oxanium&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.payment-box {
  display: flex;
  flex-direction: column;
  width: 312px;
  height: 338px;
  /* border: 2px solid white; */
}
.box-text1 {
  color: #fff;
  text-align: center;
  font-family: Rowdies;
  font-size: 24px;
  font-style: normal;
  font-weight: 1000;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  padding-top: 15px;
}
.box-text2 {
  color: #fff;
  text-align: center;
  font-family: Comic Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  padding-top: 20px;
}
/* old payment */
utton {
  cursor: pointer;
}

.Paycontainer {
  /* background: radial-gradient(111.43% 111.43% at 0% 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 100%)
  
  ;
  backdrop-filter: blur(42px);
 
  border: 1px solid white;
  border-radius: 0%;
  display: flex;
  justify-content:space-between;
  margin-right: 5vw;
  */

  width: 68vw;
  height: 75vh;
  color: white;
  margin-top: 10vh;
  overflow-y: scroll;
}

.Paycontainer::-webkit-scrollbar {
  display: none;
}

.Paycontaineralumn {
  width: 71vw;
  height: 80vh;
  background: radial-gradient(
      111.43% 111.43% at 0% 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(42px);
  margin-top: 10vh;
  border: 1px solid white;
  border-radius: 0%;
  display: flex;
  justify-content: flex-start !important;
  /* align-items: center; */
  margin-right: 5vw;
  color: white;
}

.Payleft {
  margin-left: 55px;
  /* margin-top: 100px; */
}

.payheading {
  font-family: "Oxanium", cursive;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
}

.amountBox {
  margin-top: 24px;
  padding-top: 29px;
  padding-left: 25px;
  padding-right: 26px;
  padding-bottom: 18px;
  max-width: 358px;
  /* height: 209px; */
  border: 0.5px solid rgba(255, 255, 255, 0.6);
}

.PayAmount {
  display: flex;
  justify-content: space-between;
}

.PayAmount p {
  font-family: "Oxanium", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 16px;
}

.Payline1 {
  margin-top: 4px;
  border-bottom: 1px solid white;
  max-width: 303px;
  height: 0px;
}

.TotalAmount {
  margin-top: 17px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PayTaxes {
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  padding-left: 4px;
}

.Paylarge {
  font-family: "Oxanium", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
}

.PayAccommodation {
  font-family: "Oxanium", cursive;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  max-width: 303px;
}

.Payevent {
  margin-top: 40px;
  font-family: "Oxanium", cursive;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  max-width: 358px;
}

.yesNo {
  display: flex;
  gap: 15px;
  margin-top: 26px;
  margin-bottom: 20px;
}

.agree-terms-and-conditions {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.agree-a:hover {
  text-decoration: underline;
}

.yesbtn {
  background: radial-gradient(
    100% 672.75% at 100% 100%,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid transparent;

  border-image-source: radial-gradient(
      115.98% 168.03% at -3.2% 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-image-slice: 1;
  /* backdrop-filter: blur(8px); */
  width: 72px;
  height: 36px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  justify-content: center;
  transition-duration: 500ms;
}

.yesbtn:hover {
  border-image-source: radial-gradient(
    115.98% 168.03% at -3.2% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.nobtn {
  background: radial-gradient(
    100% 672.75% at 100% 100%,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid transparent;

  border-image-source: radial-gradient(
      115.98% 168.03% at -3.2% 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-image-slice: 1;
  /* backdrop-filter: blur(8px); */
  width: 72px;
  height: 36px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  justify-content: center;
  transition-duration: 500ms;
}

.nobtn:hover {
  border-image-source: radial-gradient(
    115.98% 168.03% at -3.2% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.PayNowBtn {
  padding: 8px 24px;
  width: 110px;
  height: 36px;
  border: 0.5px solid white;
  background: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: white;
}

.PayNowBtn:hover {
  border: 0.5px solid white;
  padding: 8px 24px;
  width: 110px;
  height: 36px;
  border: none;
  background: rgba(255, 255, 255, 0.6);
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}

.PayNowBtnActive {
  padding: 8px 24px;
  width: 110px;
  height: 36px;
  border: none;
  background: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}

.MPayNowBtnActive {
  padding: 8px 24px;
  width: 110px;
  height: 36px;
  border: none;
  background: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}
.PaymentClosedAll {
  width: 18vw;
}
.PayRight {
  margin-left: 100px;
  margin-top: 55px;
  margin-right: 8px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.Payimg {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 55px;
}

.payRightHeader {
  margin-top: -15px;
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.PaymentClosedAll1 {
  width: 25vw;
}
.Payevntnm {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.Payevntnm {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.PayTeamNm input {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 12px 16px;
  width: 428px;
  height: 40px;
  border: 1px solid #ffffff;
  background: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: white;
}

.PayTeamNm input::placeholder {
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.Payline2 {
  border-bottom: 1px solid white;
  max-width: 428px;
  height: 0px;
}

.payheadContainer {
  margin-top: 30px !important;
  width: 428px;
  height: 40px;
  background: #ffffff;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.payheader {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #333333;
  padding-top: 12px;
  padding-left: 15px;
  padding-bottom: 13px;
}

.paylistContainer {
  margin-top: -16px;
  width: 428px;
  max-height: 135px !important;
  overflow-y: scroll;
  border: 1.5px solid white;
  background: radial-gradient(
    100% 672.75% at 100% 100%,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* backdrop-filter: blur(8px); */
}

.paylistContainer ul {
  padding-left: 0px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #ffffff;
  list-style: none;
}

.paylistContainer ul li {
  padding: 8px 0px 5px 22px;
}

.paylistContainer ul li:hover {
  background-color: black;
}

.payarrow {
  color: white;
  position: relative;
  top: -28px;
  right: -385px;
}

.payDetailScroll {
  overflow-y: scroll;
  margin-top: -20px;
  height: 53vh;
  padding-top: 15px;
  padding-right: 10px;
}
.paymentsuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pay-table {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  height: 50vh;
  overflow-y: hidden;
  width: 65vw;
  overflow-x: hidden;
  /* padding: 20px; */
}

.pay-table::-webkit-scrollbar {
  display: none;
}

.par-head {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 60vw;
  height: 5vh;
  /* gap: 10vw; */
}

.pay-tr-head {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 60vw;
  height: 5vh;
  gap: 6vw;
}
/* .PaymentClosedAll {
  width: 25vw;
}
.PaymentClosedAll1 {
  width: 25vw;
} */

.pay-tr {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 60vw;
  height: 5vh;
  gap: 6vw;
}

.pay-th {
  display: flex;
  justify-content: flex-start;
  width: 8vw;
  color: #fff;
  font-family: Comic Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 8px; */
  letter-spacing: -0.48px;
}

.fle-ro2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
  gap: 25px;
}
.fle-ro22 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
}
.add-par-id {
  /* margin-right: 3vw; */
  padding-right: 5vw;
  padding-left: 1vw;
  padding-top: 0.75vh;
  padding-bottom: 0.75vh;
  border-radius: 6.074px;
  border: 1.518px solid var(--1, #505050);
  background: #fff;
  box-shadow: 1.51848px 3.03695px 0px 0px #505050;
}
.clear-par-1 {
  height: 3.5vh;
  margin-right: 2vw;
  padding-right: 1vw;
  padding-left: 1vw;
  border-radius: 6.074px;
  border: 1.518px solid var(--1, #505050);
  background: #fff;
  box-shadow: 1.51848px 3.03695px 0px 0px #505050;

  /* padding:"6px"; */
  padding-top: 0.5vh;
  padding-bottom: 3vh;
  font-size: "16px";
}
.clear-par {
  margin-right: 2vw;
  padding-right: 1vw;
  padding-left: 1vw;
  border-radius: 6.074px;
  border: 1.518px solid var(--1, #505050);
  background: #fff;
  box-shadow: 1.51848px 3.03695px 0px 0px #505050;

  padding: "6px";
  font-size: "16px";
}
.submit-par {
  padding-right: 1vw;
  padding-left: 1vw;
  border-radius: 6.074px;
  border: 1.518px solid var(--1, #505050);
  background: #ccc;
  box-shadow: 1.51848px 3.03695px 0px 0px #505050;

  padding: "6px";
  font-size: "16px";
}
.submit-par-1 {
  padding-right: 1vw;
  padding-left: 1vw;
  border-radius: 6.074px;
  border: 1.518px solid var(--1, #505050);
  background: #ccc;
  box-shadow: 1.51848px 3.03695px 0px 0px #505050;

  /* padding:"6px"; */
  font-size: "16px";
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font-size: "16px";
}
.add-parti {
  color: #111010;
  font-family: Comic Neue;
  /* font-size: 2.2vw; */
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.48px;
  font-size: 2.5vw;
}
.add-par {
  margin-right: auto;
  font-size: 1vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  justify-content: center;
  align-items: center;
}
/* .logout_body{
  height: 207px;
} */
.dacc {
  color: #111010;
  font-family: Comic Neue;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.48px;
  display: flex;
  /* left: 5%; */
}
.add-acco {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  /* left: 5%; */
}

.pay-del {
  display: flex;
  gap: 40px;
}

/* color: #FFF;
font-family: Comic Neue;
font-size: 1.3vw;
font-style: normal;
font-weight: 400;
letter-spacing: -0.48px;
} */
.add-participant {
  color: #fff;
  font-family: Comic Neue;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: -0.4px;
  margin-top: 2vh;
  background: #f7b900;
  padding: 8px;
  width: 11vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-participant:hover {
  color: #f7b900;
  background: #fff;
}
.total-pay {
  display: flex;
  height: 13vh;
  /* width: 30vw; */
  margin-top: 10px;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  gap: 2vw;
  padding: 10px;
  /* margin-left: 35vw; */
}
.total-pay-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.total-pay-1-h1 {
  display: flex;
  color: #fff;
  font-family: Comic Neue;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 32px */
  letter-spacing: -0.64px;
}
.total-pay-1-h2 {
  display: flex;
  color: #fff;
  font-family: Comic Neue;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.4px;
}

.total-pay-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.total-pay-1-p1 {
  color: #fff;
  font-family: Comic Neue;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.64px;
}
.total-pay-1-p2 {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  font-family: Comic Neue;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.4px;
}

.total-pay-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 31vw;
}

.total-pay-3-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 5vw; */
  padding: 10px 25px;
  background: #fff;
  color: #333;
  font-family: Lato;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.total-pay-3-btn:hover {
  color: #fff;
  background: #333;
}

.does-he {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 1150px) {
  .Paylarge {
    font-size: 25px;
  }
}
@media only screen and (max-width: 1030px) {
  .pay-th {
    font-size: 12px;
  }
  .Paylarge {
    font-size: 20px;
  }
  .Payevent {
    font-size: 14px;
  }
  .agree-a {
    font-size: 14px;
  }
  .yesbtn {
    font-size: 14px;
  }
  .nobtn {
    font-size: 14px;
  }
}
@media only screen and (max-width: 970px) {
  .Paylarge {
    font-size: 15px;
  }
  .PayAmount p {
    font-size: 14px;
  }
  .Payevent {
    font-size: 12px;
  }
  .agree-a {
    font-size: 12px;
  }
  .yesbtn {
    font-size: 12px;
  }
  .nobtn {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .pay-table {
    width: 88vw;
    padding: 2vh 2vw;
    height: 46vh;
  }
  .PaymentClosedAll {
    width: 35vw;
    margin: auto;
  }
  .PaymentClosedAll1 {
    width: 50vw;
    margin: auto;
  }
  .Payleft1 {
    overflow-y: scroll;
  }
  .pay-tr-head {
    width: 88vw;
    gap: 10vw;
  }
  .pay-th {
    width: 20vw;
  }
  .pay-tr {
    width: 83vw;
    gap: 10vw;
  }
  .add-participant {
    font-size: 14px;
    margin-left: 7px;
    width: 125px;
  }
  .total-pay {
    width: 88vw;
    flex-direction: column;
    border: none;
  }
  .total-pay-up {
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  .total-pay-1-h1 {
    font-size: 25px;
  }
  .total-pay-1-h2 {
    font-size: 15px;
  }
  .total-pay-1-p1 {
    font-size: 25px;
  }
  .total-pay-1-p2 {
    font-size: 15px;
  }
  .total-pay-3 {
    margin-left: 0;
    justify-content: flex-start;
  }
  .total-pay-3-btn {
    font-size: 15px;
    padding: 10px;
    width: 110px;
  }
  .par-head {
    width: 83vw;
  }
  .agree-terms-and-conditions {
    margin-top: 10px;
  }
  .PayNowBtnActive,
  .PayNowBtn {
    margin-bottom: 20px;
  }
  .mob-incl-all-tax {
    font-size: 13px !important;
  }
  /* .main_boxx::-webkit-scrollbar{
    display: block;
    width: 2px;
    color: #ded7d7;
    border-radius: 0.5px;
  } */
}
@media only screen and (max-width: 500px) {
  .PaymentClosedAll {
    width: 65vw;
    margin: auto;
  }
  .PaymentClosedAll1 {
    width: 65vw;
    margin: auto;
  }
}
@media only screen and (max-width: 430px) {
  /* .fle-ro2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -1.1vh;
  
  
    position:absolute;
    top:71%;
    left: 32%;
    right:unset;
  } */

  .add-par {
    /* margin-right: 22vw; */
    font-size: 0vh;
    display: flex;
    flex-direction: column;
    gap: 19px;
    position: absolute;
    /* top: 26%;
    left: 25%;
    align-items: center; */
  }
}

@media only screen and (max-width: 350px) {
  .dacc {
    font-size: 13px;
  }
  .submit-par {
    font-size: 10px !important ;
  }
  .clear-par {
    font-size: 10px !important;
  }
}
