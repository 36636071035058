/* Add this CSS to your stylesheet or use inline styles */
.spline-container {
  position: relative;

  width: 100%;
  height: 210vh; /* or any height you need */
  /*overflow: hidden; !* To prevent unwanted overflow *!*/
  display: flex;
  justify-content: center; /* Center the Spline content */
  align-items: center;
}
.spline-container div{
  position: sticky;
  /*width: 100%;*/
  /*height: 100%;*/
  bottom: 0;
  /*z-index: 1;*/
}

/* Ensure the Spline canvas respects the container */
.spline-container canvas {
  width: 100%;
  height: auto;
}
.canvascontainer{
  height: 100vh;
}
