.ECD-container{
  display: flex;
  flex-direction: column;
  width: 83.6vw;
}

.ECD-searchbar {
  background: radial-gradient(
    237.5% 9923.25% at 28.55% -41.25%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  /* backdrop-filter: blur(8px); */
  border: 1px solid transparent;
  border-image-source: linear-gradient(
    100.63deg,
    rgba(255, 255, 255, 0) -9.97%,
    black 176.97%
  ) !important;
  border-image-slice: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: black;
  height: 60px;
  min-width: 150px;
}
.ECD-card-Display {
  margin-bottom: 4px;
  display: flex;
}


.mobile-search-bar {
  display: none;
}
@media screen and (max-width: 768px) {
  .ECD-container {
    align-items: center;
    min-height: calc(100vh - 80px);
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .mobile-search-bar {
    display: contents;
    margin-top: 20px;
  }
  .mobile-search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
  }
  .all-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    gap: 68px;
    border: 1px solid #ffffff;
    letter-spacing: -0.02em;
    color: #ffffff;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

.ECD-searchbar-main {
  max-width: 266px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(
      237.5% 9923.25% at 28.55% -41.25%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  /* backdrop-filter: blur(8px); */
  padding: 12px 16px;
  border: 1px solid transparent;
  border-image-source: linear-gradient(
    100.63deg,
    rgba(255, 255, 255, 0) -9.97%,
    black 176.97% 
  ) !important;
  border-image-slice: 1;
  transition-duration: 500ms;
}


.ECD-searchbar-main:hover{
  border-image-source: radial-gradient(
    115.98% 168.03% at -3.2% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
}


.ECD-searchbar-text-mian {
  letter-spacing: -0.02em;
  color: black !important;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: transparent;
  border: none;
  outline: none;
}
.widd{
width: 215px;
}
.pointer{
  cursor: pointer;
}
.ECD-searchbar-text-mian::placeholder{
  color:black;
  font-family: salsa;
}
.ECD-searchbar-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  margin: auto;
  color: black;
  /* position: fixed;s */
}
.ECD-card-Display {
  margin-top: 32px;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    column-gap: 10px;
    /* max-height: 100vh; */
    padding-bottom: 200px;
    /* justify-content: space-evenly; */
    gap: 24px 15px;
    padding-left: 1vw;
}
/* .ECD-card-Display::-webkit-scrollbar{
  width: 0;
  display: none;
} */

@media screen and (max-width: 768px) {
  .ECD-card-Display {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
  }
}
.none{
  display: none;
}
.searchbar-act{
  margin-top: 10px;
  background: transparent;
  outline: none;
  color: white;
  width: 70vw;
  border: none;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
}
.cross{
  display: contents;
}