@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Saira:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic');
.MUN-bg {
    /* background: url("../../../assests/BGMUN.webp"); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    position:absolute;
    z-index: -2;
    width: 100vw;
    /* padding-bottom: 10vh; */
    overflow-y: hidden;
    background-color: #0b1b35;
}

.MUN-Bg{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index:-1;
    width:100vw;
    height: max-content;
}

.Bg-top {
    position: relative;
    width:100vw;
    /* height: 100% !important; */
}
.Bg-bottom {
    position: relative;
    width:100vw;
    /* height: 100% !important; */
    /* height: 550vh; */
}

.topImgMUN
{
    width: 56vw;
}


.top-MUN-img {
    display: flex;
    justify-content: center;
    margin-top: 14vh;
}

.registerNowBtn {
    display: flex;
    justify-content: center;

}

.registerNowBtnClick {
    border-radius: 16px;
    color: #000;
    font-family: Saira;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 32px */
    letter-spacing: 1.6px;
    padding: 1vh 2.5vw;
    background-color: #fff;
    margin-top: 16vh;
    cursor: pointer;
}
.registerNowBtnClick:hover
{
    background-color: #FDD52E;
}

.prizesWorth {
    border-radius: 14px;
    /* background: rgba(8, 48, 114, 0.40); */
    color: black;
    text-align: center;
    font-family: Saira;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 32.44px */
    letter-spacing: 1px;
    width: 80vw;
    margin: auto;
    margin-top: 25vh;
    padding: 1.5vh 2vw;
    padding-bottom: 2.5vh;
    border: 5px solid #F9C438;
}

.prizesWorth1 {
    width: 25vw;
    display: block;
    margin-bottom: 5vh;
}

.prizesWorthDiv {
    display: flex;
    justify-content: center;
    color: black;
text-align: center;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 162.2%; /* 32.44px */
letter-spacing: 1px;
}

.mun_footer_web{
    background:none !important;
    background-color: white !important;
}

.clearbgmun{
    background:none !important;
    background-color: rgba(0, 8, 21, 0.50) !important;
    box-shadow: 1px 7px 30px 59px rgba(0, 0, 0, 0.07) !important;
}

.MUNjuDges
{
    margin-top: 35vh;
}
.MUNCommitee
{
    margin-top: 28vh;
}
.MUNFooter
{
    margin-top: 10vh;
}

.mun_mob_bg{
    display: none;
}
.MUN-mob{
    display: none;
}

@media only screen and (max-width:768px){

.mun_mob_bg {
    display: none;
}
.MUN-bg{
    display: none;
}
.MUN-mob{
    display: block;
}
}

@media only screen and (max-width:768px) {
    .prizesWorth {
        width: 75vw;
    }

    .prizesWorth1 {
        width: 60vw;
    }
}
@media only screen and (max-width:1000px)
{
    .topImgMUN
{
    width: 40vw;
}
}
@media only screen and (max-width:1450px)
{
    .prizesWorth
    {
        margin-top: 25vh;
    }
}
@media only screen and (max-width:1400px)
{
    .prizesWorth
    {
        margin-top: 20vh;
    }
}
@media only screen and (max-width:1300px)
{
    .prizesWorth
    {
        margin-top: 75vh;
    }
}
@media only screen and (max-width:1200px)
{
    .prizesWorth
    {
        margin-top: 10vh;
    }
}
@media only screen and (max-width:1100px)
{
    
    .registerNowBtnClick
    {
        margin-top: 13vh;
    }
}
@media only screen and (max-width:768px)
{
    
    .prizesWorth
    {
        margin-top: 4vh;
    }

    /* .MUN-Bg {
        display: none !important;
    } */

    .mun_mob_bg {
        display: block !important;
    }

    .iitr_mun {
        width: 80vw !important;
    }
}

.iitr_mun {
    width: 50vw;
}