
.paymentsuccessful{
    display: flex;
    flex-direction: column;
}
.sponImg{
    /* display: none; */
    margin-bottom: -0.5rem;
    width: 24rem;
}
.pay-success{
    overflow-y: scroll;
}
@media only screen and (max-width:1000px){
.sponImg{
    margin-bottom: -1.5rem;
    width: 19rem;
}
}
@media only screen and (max-width:768px){
    .pay-success{
        margin: auto !important;
    }
    .sponImg{
        margin-bottom: 0rem;
        width: 22rem;
    }
    .paymentsuccessful{
        display: flex;
        flex-direction: column;
    };
    
}
@media only screen and (max-width:450px){
    .sponImg{
    width: 14rem;
    }
}