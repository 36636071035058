.all_events {
margin: 6px 0px 3px 8px;
    
}

.events {
    margin: 0px 0px 10px 8px;
}

.bold{
    font-weight: bold;
    font-size: 18px;
}