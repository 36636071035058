@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');

@keyframes moveTrain {
  0% {
    left: 50%; /* Start with half of the train image visible */
    transform: translateX(-50%);
  }
  100% {
    left: -100%; /* End off-screen to the left */
    transform: translateX(0);
  }
}

.blue-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    background-color: rgb(212, 221, 230);
}

.home_wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
}
.home_wrapper::-webkit-scrollbar{
    display: none;
    width:0;
}

.nav {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 5vw;
    position: absolute;
    z-index: 10;
}

.navlogo img {
    height: 64px;
}

.navigation {
    display: flex;
    gap: 3vw;
    font-family: "Averia Serif Libre", serif;
}

.nav-link {
    color: #fff;
    text-decoration: none;
    margin: auto;
}

.nav-btn {
    padding: 8px 32px;
    font-size: 16px;
    font-family: "Averia Serif Libre", serif;
    border: 0;
    border-radius: 8px;
}

.sec1 {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -11vh;
}

.backdrop {
    width: 100vw;
    height: 130vh;
    position: absolute;
}

.backdrop-mob {
    display: none;
}
.backdrop-mob2 {
    display: none;
}

.sec1-title {
    margin-top: 10vh;
    width: 50vw;
}

.sec1-btns {
    display: flex;
    gap: 3vw;
    margin-top: 2vw;
    z-index:10; 
}

.sec1-btns button {
    padding: 8px 32px;
    font-size: 22px;
    font-family: "Averia Serif Libre", serif;
    border: 0;
    border-radius: 8px;
    background-color: #004D9D;
    color: #fff;
}

.sec1-5 {
    height: fit-content;
    position: absolute;
    top: 80vh;
}

.sec1-ender {
    position: absolute;
    height: 130vh;
    width: 120vw;
    z-index: 5;
    margin-left: -10vw;
}

.sec1-bird {
    margin: 36vh 16vw;
    position: absolute;
    z-index: 10;
    /* max-width: 1000px; */
}

.sec2 {
    /* position: relative; */
    width: 100vw;
    margin-top: 90vh;
    /* background-image: url(/public/section2-2d-bg.png); */
}

.backdrop2 {
    width: 100vw;
    position: absolute;
    /* margin-top: 80vh; */
    top: 150vh;

}

.about {
    background-color: #aa4b04;
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 45vw;
    margin: auto;
    position: relative;
    border-radius: 12px;
    opacity: 0.9;
    margin-top: 40vh;
}

.about p {
    font-family: 'Salsa', sans-serif;
    color: #f3c790;
    text-align: center;
    line-height: 25px;
}

.sec-2-5 {
    position: relative;
    z-index: 10;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
}

.sec2-ender {
    position: absolute;
    width: 100vw;
}

.sec2-title {
    position: absolute;
    /* margin: 500px 500px; */
    bottom: 400px;
}

.train-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 100vh;
  z-index: 115;
  margin-bottom: max(83vh, 690px);
}

.train {
  position: absolute;
  height: 1000px;
  left: 100%;
  transform: translateX(-50%);
  animation: moveTrain 20s linear infinite;
}


@media only screen and (max-width: 450px) {
    .backdrop {
        display: none;
    }
    .backdrop2 {
        display: none;
    }
    .backdrop-mob {
        display: block;
        width: 100vw;
        height: 115vh;
        position: absolute;
    }
    .backdrop-mob2 {
        display: block;
        width: 100vw;
        height: 90vh;
        position: absolute;
    }
    .sec1-title {
        z-index: 15;
        margin-top: 18vh;
        margin-bottom: 5vh;
    }
    .sec1-5 {
        top: 80vh !important;
    }
    .sec1-bird {
        width: 70vw;
        margin: 0vh 16vw !important;
    }
    .sec1-ender {
        display: none;
    }
    .sec1-btns {
        z-index: 1;
    }
    .sec1-btns button {
        padding: 8px 18px;
        font-size: 16px;
        border: 1px white solid;
    }
    .sec2 {
        margin-top: 63vh;
    }
    .about {
        margin-top: 5vh;
        padding: 1rem 1rem;
        width: 70vw;
    }
    .about img {
        width: 40vw;
    }
    .about p {
        font-size: smaller;
        line-height: 20px;
    }
    .train-wrapper {
        margin-top: 20vh !important;
        margin-bottom: 27vh !important;
    }
    .train {
        height: 500px;
    }
}
@media only screen and (max-width: 800px) {
    .backdrop {
        display: none;
    }
    .backdrop2 {
        display: none;
    }
    .backdrop-mob {
        display: block;
        width: 100vw;
        height: 115vh;
        position: absolute;
    }
    .backdrop-mob2 {
        display: block;
        width: 100vw;
        height: 90vh;
        position: absolute;
    }
    .sec1-title {
        z-index: 15;
        margin-top: 18vh;
        margin-bottom: 5vh;
    }
    .sec1-bird {
        width: 70vw;
        margin: 30vh 16vw;
    }
    .sec1-ender {
        display: none;
    }
    .sec1-btns {
        z-index: 1;
    }
    .sec1-btns button {
        padding: 8px 18px;
        font-size: 16px;
        border: 1px white solid;
    }
    .sec2 {
        margin-top: 63vh;
    }
    .about {
        margin-top: 5vh;
        padding: 1rem 1rem;
        width: 70vw;
    }
    .about img {
        width: 40vw;
    }
    .about p {
        font-size: smaller;
        line-height: 20px;
    }
    .train-wrapper {
        margin-top: 10vh;
        margin-bottom: 34vh;
    }
    .train {
        height: 500px;
    }
}

@media only screen and (max-width: 1100px) {
    .sec1-title {
        width: 80%;
    }
}

@media only screen and (min-width: 1550px) {
    .sec1-bird {
        margin: 45vh 30vw;
    }
    .about p {
        font-size: 24px;
        line-height: 40px;
    }
    .train-wrapper {
        margin-top: 110vh;
        margin-bottom: 45vh;
    }
    .sec1-5 {
        top: 75vh;
    }
}