@import url(https://fonts.googleapis.com/css?family=Saira:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
.ContactUsMUN
{
    border-radius: 25px;
    background: rgba(0, 8, 21, 0.50);
    box-shadow: 1px 7px 30px 59px rgba(0, 0, 0, 0.07);
    padding-bottom: 5vh;
}
.MUN-bottom
{
    width: 80vw;
    margin: 0 auto;
}

.heading-contact
{
    display: flex;
    justify-content: center;
    color: var(--Surface-main, #FFF);
    font-family: Saira !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 32.5px */
    letter-spacing: 1.25px;
    margin-bottom: 7vh;
    padding-top: 4vh;
}
.detailsContact
{
    margin-left: 7vw;
}
.NameContact
{
    color: #FFF;
    text-align: left;
    font-family: Saira !important;
    font-size: 18.218px;
    font-style: normal;
    font-weight: 700;
    line-height: 162.2%; /* 29.55px */
}
.phoneContact
{
    color: rgba(255, 255, 255, 0.63);
    font-family: Saira !important;
    font-size: 15.41px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%; /* 16.886px */
    display: flex;
    align-items: center;
}
.contact1MUN
{
    margin-bottom: 5vh;
}
.contact2MUN
{
    margin-bottom: 5vh;
}

.phoneContact
{
    margin-top: 1vh;
}
.LogoMUN
{
    margin-top: 10vh;
}
.directionsHeading
{
    color: #FFF;
    font-family: Saira !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 75% */
    letter-spacing: -0.64px;
    margin-top: 12vh;
    margin-bottom: 5vh;
}
.directionElements
{
    color: #FFF;
    font-family: Saira;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.directionElement
{
    padding-top: 1.8vh;
}
@media only screen and (max-width:350px)
{
    .directionsHeading
    {
        font-size: 30px;
    }   
}
.socialmediaMUN
{
    display: flex;
    justify-content: space-between;
    width: 75vw;
    margin-top: 10vh;
    padding:0 0 2rem 0;
}
