*{
    margin: 0;
    padding: 0;
}



.logo-res {
  width: 100px;
  margin: 16px 34px;
  cursor: pointer;
  }

.regbg {
    position: absolute;
    width: 100vw;
    height: 100vh;
 
    z-index: -100;
    display: block;
  }
  .regbg1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
  
    z-index: -100;
    display: none;
  }


  .rp-page {
  
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    position:absolute;
    top:0px;
    right:0px;
    left:0px;
    bottom: 0px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .rp-content {
    display: flex;
    position: absolute;
    top: 12vh;
    flex-direction: column;
    width: 520px;
    height: 520px;
    margin: 5% auto;
    align-items: center;

    background: transparent;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    border: 2px solid grey;
    border-radius: 8px;
    /* filter: drop-shadow(12px 12px 0 rgba(0,0,0,.4)); */

  }

  .rp-content h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: yellow;
    margin-top: 12%;
  }
  .rp-content input {
    margin: 10px;
    text-decoration: none;
    border-style: none;
    background-color: transparent !important;
    border-bottom: 1px solid white;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    color: #000;

  }


  .rp-content button {
    margin-top: 50px;
    width: 275px;
  }

  .rp-content p {
    margin-top: 55px;
    width: 60%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000;
  }

  .rp-form {
    display: flex;
    flex-direction: column;
    width: 56%;
    margin-top: 55px;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 50px;
    margin-top: 60px;
  }
  
  .rp-form img {
    width: 20px;
    position: relative;
    bottom: 20px;
    left: 250px;
    cursor: pointer;
  }

  .Form-input:hover{
    border: 1.5px solid #111;
    box-shadow: 1.5px 3px 0 0 #111;
    color: #111;
    outline: none;
    background: transparent !important;
  }

  .Form-input{
    border: 1.5px solid white;
    /* box-shadow: 1.5px 3px 0 0 #505050; */
    outline: none;
    background: transparent;
    color: white !important;
  }


  .btn_primary{
    background-color: yellow;
    border: 1.5px solid #505050;
    border-radius: 6px;
    box-shadow: 1.5px 3px 0 0 #505050;
    outline: none;
    cursor: pointer;
    font-family: Comic Sans MS;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.25rem;
    padding: 10px 20px;
    color: #000;
  }

  .btn_primary:hover{
    color: #fff;
    background-color: rgb(237,215,41);
    border: 1.52px solid #111;
    box-shadow: 1.5px 3px 0 0 #111;
  }

  
  @media only screen and (max-width: 800px) {
        
    .logo-res{
      width: 60px;
    }
    
    .regbg{
        display: none;
    
    }
    .regbg1{
        display: block;
    
    }

}

  @media only screen and (max-width: 768px) {

    .rp-content {
        margin-top: 50px;
        width: 70vw;
        height: 460px;
      }
      .rp-content h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
      }
      
      .rp-form {
        width: 65%;
        margin-top: 50px;
      }
      .rp-content button{
        width: 50vw;
      }


      
  }


