.EMP-container {
  /* background: url("../Sponsors/images/bg.svg"); */
  height: 100vh;
  width:100vw !important;
  /* background-size: cover;
  background-repeat: no-repeat; */
  flex-direction: column;
  overflow: hidden;
}
.EMP-container-new {
  /* background: url("../Sponsors/images/bg.svg"); */
  /* height: 46vh; */
  width:96vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-size: cover;
  background-repeat: no-repeat; */
  flex-direction: column;
  overflow: hidden;
}
.EMP-hero {
  overflow: hidden;
    height: 70vw;
    width:100vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
  margin-left: 0;
  /* margin-right: 40px; */
  margin-top: 3vh;
    position: absolute;
}

.eventsBack{
  width: 100vw;
  position: fixed;
  margin-top: -11vh;
}

.eventsNav{
  background-color: transparent !important;
  background: transparent !important;
  position: relative;
  z-index: 100000;
}

.Event-MObile-View{
  display: none;
}
@media screen and (max-width:768px){
  .Event-MObile-View{
    display: contents;
  }
  .EMP-container,.EMP-container-new{
    display: none;
  }
}