@import url('https://fonts.googleapis.com/css2?family=Numans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
.judges-container{
    /* border-radius: 22.289px;
    background: rgba(0, 17, 46, 0.40);
    color: aliceblue;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75vw;
    gap: 15vh;
    margin: 0 auto;
    padding: 5vh 10vw 25vh 10vw; */
    background-color: rgba(0, 17, 46, 0.40);
    box-shadow: 6px 6px 4px 0px #00000024 inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    border-radius: 10px;
    margin: auto;
    padding: 20px;
}
.head-judge{
    display: flex;
    justify-content: center;
    color: #FDD52E;
    text-align: center;
    font-family: Bangers;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
.judge-box-cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
}
.jelement1{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;   
    margin-top: 1.2vh;
}
.judges-cont-1{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    /* width:52vh; */
    
}
.judges-cont-2{
    display: flex;
    flex-direction: column;
    gap: 1.2vh;
    margin-top: 7vh;
}
.judges-cont-3{
    display: flex;
    flex-direction: column;
    gap: 1.2vh;
}
.hero1{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 19.746px;
    border: 3.291px solid #FDD52E;
}
.hero2{
    width: 100%;
    height: 42vh;
    border-radius: 19.746px;
    border: 3.291px solid #FDD52E;

}
.hero3{
    width: 100%;
    height: 42vh;
    border-radius: 19.746px;
    border: 3.291px solid #FDD52E;
}
.jelement2{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1vw; 
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.judges-cont-1,.judges-cont-2,.judges-cont-3{
width: 15vw;
height: 52vh;
}

@media only screen and (max-width: 768px){
    .judges-container{
        gap: 7vh;
        padding: 5vh 10vw;
        width: 80vw;
    }
    .judge-box-cont{
        flex-direction: column;
        gap: 10vh;
    }
    .judges-cont-1,.judges-cont-2,.judges-cont-3{
        justify-content: center;
        align-items: center;
    }
    .judges-cont-2{
        margin-top: 0;
    }
    .hero1{
        width: 50vw;
        height: auto;
    }
    .head-judge{
        font-size: 10vw;
    }
    .jelement1{
        font-size: 5.3vw;
        margin-top: 2vh;
    }
    .jelement2{
        font-size: 4.2vw;
    }
}