.bgwrappers4{
    position: absolute  ;
    width: 100%;
}

.eventimgflex{
    position: absolute;
    display: flex;
    height: 51vw;
    background-color: antiquewhite;
    width: 95%;
    /* justify-content: space-between; */
    align-items: end;
    /* padding: 0 2vw;*/
}

.eventimgflex img{
    width: 16vw;
    height: 20vw;
    margin-left: 1vw;
}

.eventimgflex>.imgevent2{
    margin-left: 4vw;
    object-fit: cover;
    /* left: 80%; */
}

.eventimgflex>.imgevent3{
    margin-left: 5vw;
    object-fit: cover;
    /* left: 80%; */
}

.eventimgflex>.imgevent4{
    margin-left: 5vw;
    object-fit: cover;
    /* left: 80%; */
}

.eventimgflex>.imgevent5{
    margin-left: 4vw;
    object-fit: cover;
    /* left: 80%; */
}
.imgevent1:hover{
    cursor: pointer;
}
.imgevent2:hover{
    cursor: pointer;
}
.imgevent3:hover{
    cursor: pointer;
}
.imgevent4:hover{
    cursor: pointer;
}
.imgevent5:hover{
    cursor: pointer;
}

.btn-home-img{
    /* width: 10vw; */
    /* background-color:#AD0D02 ; */
    margin-left: 1.5vw;
    width: 9vw;
    height: 3vw;
    /* color: white; */
    /* border-color: white; */
    /* border-radius: 5px; */

    border-radius: 0.27756rem;
border: 0.888px solid #FFF;
background: #AD0D02;

    color: #FFF;
font-family: Salsa;
font-size: 1.3vw;
font-style: normal;
font-weight: 400;
line-height: 3vw; /* 120% */
/* letter-spacing: 0.02219rem; */
    
}

.btn-home-img:hover{
    transform: scale(1.05);
    background-color: white;
    color: black;
}

.btn-home-img:active{
    transform: scale(0.95);
}

.btnimgclicked{
    background-color: white;
    color: black;

    margin-left: 1.5vw;
    width: 9vw;
    height: 3vw;
    /* color: white; */
    /* border-color: white; */
    /* border-radius: 5px; */

    border-radius: 0.27756rem;
border: 0.888px solid #FFF;
/* background: #AD0D02; */

    /* color: #FFF; */
font-family: Salsa;
font-size: 1.3vw;
font-style: normal;
font-weight: 400;
line-height: 1.33219rem; /* 120% */
letter-spacing: 0.02219rem;
}