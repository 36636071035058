.ECD-container {
  display: flex;
  flex-direction: column;
  width: 83.6vw;
}

.ECD-searchbar {
  background: radial-gradient(237.5% 9923.25% at 28.55% -41.25%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%);
  /* backdrop-filter: blur(8px); */
  border: 0.885px solid #FFF;
  border-image-source: linear-gradient(100.63deg,
      rgba(255, 255, 255, 0) -9.97%,
      #ffffff 176.97%);
  border-image-slice: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: white;
  height: 60px;
  min-width: 150px;
  gap: 10px
}

.ECD-card-Display {
  margin-bottom: 4px;
  display: flex;
}

.ECD-card-Display::-webkit-scrollbar {
  /* display: none !important; */
}

.mobile-search-bar {
  /* display: none; */
}

@media screen and (max-width: 768px) {
  .ECD-searchbar {
    background: radial-gradient(237.5% 9923.25% at 28.55% -41.25%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    /* backdrop-filter: blur(8px); */
    border: 2px solid #FFF;

    border-image-slice: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    color: white;
    height: 40px;
    min-width: 110px;
    gap: 10px;
    filter: brightness(0);
  }

  .ECD-container {
    width: 80vw;
    display: flex;
    height: 200vw;
    padding-bottom: 100px;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .mobile-search-bar {
    display: contents;
    margin-top: 20px;
  }

  .mobile-search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
  }
  .ESM-Conatiner-list::-webkit-scrollbar{
    /* display: none !important; */
  }
  .all-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    gap: 68px;
    border: 1px solid #ffffff;
    letter-spacing: -0.02em;
    color: #ffffff;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

.ECD-searchbar-main {
  max-width: 266px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(237.5% 9923.25% at 28.55% -41.25%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  /* backdrop-filter: blur(8px); */
  padding: 12px 16px;
  border: 1px solid transparent;
  border-image-source: linear-gradient(100.63deg,
      rgba(255, 255, 255, 0) -9.97%,
      #ffffff 176.97%);
  border-image-slice: 1;
  transition-duration: 500ms;
}


.ECD-searchbar-main:hover {
  border-image-source: radial-gradient(115.98% 168.03% at -3.2% 0%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.05) 100%);
}


.ECD-searchbar-text-mian {
  letter-spacing: -0.02em;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: transparent;
  border: none;
  outline: none;
}

.widd {
  width: 215px;
}

.pointer {
  cursor: pointer;
}

.ECD-searchbar-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  margin: 20px auto;
  /* position: fixed;s */
}

.ECD-card-Display {
  margin-top: 32px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  column-gap: 10px;
  /* max-height: 100vh; */
  padding-bottom: 100px;
  justify-content: flex-start;
  gap: 30px 24px;
  height: 74vh;
  overflow-y: auto;
}
.ECD-card-Display-new {
  /* margin-top: 32px; */
  display: flex;
  width: 92vw;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10px;
  /* max-height: 100vh; */
  /* padding-bottom: 100px; */
  justify-content:center;
  gap: 30px 24px;
  height: 35vw;
  overflow-y: auto;
}
.ECD-card-Display::-webkit-scrollbar{
  display: none !important;
  width: 0 !important;
}
.ecdwrap{
  width:92vw;
  height: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px 24px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.eventmain-headline {
  color: #FFF;
  font-family: Super Comic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

@media screen and (max-width: 768px) {
  .ECD-card-Display {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height:300vw;
  }
}

.none {
  display: none;
}

.searchbar-act {
  margin-top: 10px;
  background: transparent;
  outline: none;
  color: white;
  width: 70vw;
  border: none;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
}

.cross {
  display: contents;
}