
.main3d{
    background-color: white;
    width:100vw;
    height:100vh;

}
.ccanva{
    width: 100vw;
    height: 100vh;
    background: transparent;
}