.mobfooterwrapper{

    /* display: flex;
    flex-direction: column;
    align-items: center; */

    padding-left: 20vw;

    width: 100%;
    background: linear-gradient(180deg, #429EFF 0%, #D3E9FF 42%);
    height: 100%;
    padding-bottom: 4vw;
    position: relative;
    bottom: 0;
    margin-top: 62vw;
    transition: transform 1s ease-in-out;
    /* transform: translateY(10%); */
    z-index: -10;
    padding-top: 100vw;
}


.logo-footermob>img{
    width: 23vw;
}

.number{
    margin-top: 4vw;
}

.dxnfootermob{
    /* margin-top: 7vw; */
}




.footermobhead{
    margin-top: 7vw;
    color: #284551;
font-family: Salsa;
font-size: 4vw;
font-weight: 600;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 1.575rem */
/* letter-spacing: 0.05625rem; */
}

.footermobcontent{
    color: #284551;
font-family: Salsa;
font-size: 3vw;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 1.3rem */
margin-top: 2vw;
/* letter-spacing: 0.04063rem; */
}