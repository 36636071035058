@import url('https://fonts.cdnfonts.com/css/boecklins-universe');

.heading_s3{
    font-family: 'Boecklins Universe', sans-serif;
}

.wrapper_s3{
    /* background: url('./../../../assets/bg_peacock.png');
    background-repeat: no-repeat;
    background-size:cover; */
    overflow-x: hidden;
    height: 300vh;
    /* width: 150vw */
}

.wrapper_s4{
    background: url('./../../../assets/bgs4.svg') ;
    background-repeat: no-repeat;
    background-size:contain;
    /* height: 300vh; */
    height: 124vw;
    /* width: 100vw; */
    /* z-index: -1000; */
    margin-top: -148vh;
}

/* .wrapper_s5{
        background: url('./../../../assets/bgs4.svg'),
        clipPath:  url(${celeb}#clip-path-id)`, // Replace with the id of your SVG path
        backgroundSize: "cover",
} */

.celeb_pic{
    margin-top: 9vw;
}


.title_s4{
    position: absolute;
    margin-top: 85vw;
    z-index: 100;
    padding-top: 10vw;

    font-family: "Boecklins Universe";
    font-size: 9vw;
    color: #790800;
  }
  .mobwrapper_s4 {
    display: none;
    background-image: url(/src/assets/mobsection3.webp);
    width: 100vw;
    height: 2000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    margin-bottom: -700px;
    margin-top: 0px;
}



@media only screen and (max-width:1100px){
    .wrapper_s4{
        margin-top: -185vh;
    }
}

@media only screen and (max-width:930px){
    
}

@media only screen and (max-width:850px){
    .wrapper_s4{
        margin-top: -200vh;
    }
}

@media only screen and (max-width: 768px){
    .wrapper_s3{
        height: auto;
    }
    .wrapper_s4{
        display: none;
    }
    .mobwrapper_s4{
        display: block;
    }
}

/* @media only screen and (max-width:650px){
    .wrapper_s4{
        margin-top: -232vh;
    }
}

@media only screen and (max-width:650px){
    .wrapper_s4{
        margin-top: -246vh;
    }
}

@media only screen and (max-width:360px){
    .wrapper_s4{
        margin-top: -256vh;
    }
} */