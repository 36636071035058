@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.lsp-c1,
.lsp-c2,
.lsp-c3 {
  display: flex;
  gap: 12px;
  align-items: center;
}

.lsp-c1 {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 25px;
}

.lsp-c2,
.lsp-c3 {
  margin-left: 23px;
}

.lsp-img1 {
  height: 15vmin;
  width: 15vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  max-width: 170px;
  max-height: 170px;
  border-radius: 100px;
  border-style: solid;
  border-color: #FFF;
  border-width: 1px;
}

.lsp-text0 {
  color: white;
  font-size: 12px;
  font-family: Public Sans;
  margin-top: 9px;
  display: flex;
  justify-content: center;
}

.lsp-text1 {
  color: white;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  gap: 20px;
}

.lsp-text2 {
  color: white;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.lsp-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 15px;
}

.lsp-box {
  margin-top: 10vh;
  margin-left: 2vw;
  margin-right: -1vw;
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 15vw;
  height: 75vh;
  gap: 7vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid rgba(255, 255, 255,.21);
  border-radius: 5px;
  backdrop-filter: blur(2px);
 

}

.lsp-box::-webkit-scrollbar {
  display: none !important;
}

.css-b62m3t-container {
  color: black;
}

.c1-text {
  color: #ffffff;
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 20px; */
  /* display: flex;
  align-items: center; */
  letter-spacing: -0.02em;
  color: rgba(18, 17, 17, 0.98);
  /* leading-trim: both; */
  /* text-edge: cap; */
  font-family: Public Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 20px; */
  /* 125% */
  letter-spacing: -0.32px;
  text-decoration: none !important;
}

.c2-text {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 400;
  text-decoration: none !important;
  font-size: 18px;
  /* line-height: 20px; */
  /* display: flex;
  align-items: center; */
  letter-spacing: -0.02em;
  color: #FFF;
  font-family: Public Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 15.748px; 98.427% */
  letter-spacing: -0.32px;
}

.c2-text:hover {
  color: rgba(255, 255, 255, 0.8);

}


.lsp-centre {
  gap: 32px;
  display: flex;
  height: 7vh;
  flex-direction: column;
  justify-content: center;
  margin-top: 0vh;
}

.payment-profile {
  /* padding-top:70px!important; */
}

@media only screen and (max-width: 1246px) {
  .lsp-text2 {
    text-align: center;
    font-size: 13px;
  }

  .lsp-text1 {
    font-size: 16px;
  }

  .c1-text,
  .c2-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {

  .c1-text,
  .c2-text {
    font-size: 13px;
  }
}

@media only screen and (max-width:950) {
  .c1-text {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px) {

  .nnp-content {
    gap: 2vw;
  }

  /* .lsp-pic {
    padding-left: 1.5vw;
  } */

  .lsp-text1 {
    text-align: center;
    font-size: 13px;
    gap: 15px;
  }

  .lsp-text2 {
    font-size: 10px;
  }

  /* .lsp-c1 {
    margin-left: 2vw;
  } */
  .c1-text,
  .c2-text {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .lsp-text1 {
    gap: 10px;
  }
}

@media only screen and (max-width: 791px) {
  .lsp-text1 {
    text-align: center;
    font-size: 12px;
  }

  /* .lsp-pic {
    padding-left: 2vw;
  } */
  /* .lsp-text0{
    margin-left:-2vw;
  } */
  .lsp-text2 {
    font-size: 9px;
  }
}

@media only screen and (max-width:768px) {
  .lsp-text1 {
    text-align: center;
    font-size: 20px;
    gap: 25px;
  }

  .lsp-text2 {
    font-size: 16px;
  }
}

@media only screen and (max-width:360px) {
  .lsp-text1 {
    /* display: block; */
    font-size: 14px !important;
  }

  .lsp-text2 {
    /* display: block; */
    font-size: 12px !important;
  }
}