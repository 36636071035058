@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
.neweventmain{
    width:100vw;
    height:100vh;
    /* overflow: hidden; */
    user-select: none;
}
.neweventmain::-webkit-scrollbar{
    display: none !important;
    width: 0 !important;
}
.newbgevimg{
    width:100vw;
    /* overflow-y: hidden; */
    position: absolute;
    z-index: -1;
}
.newbgevimg::-webkit-scrollbar{
    display: none !important;
    width: 0 !important;
}
.newevpgtitle{
    margin-top:5vh;
    display: flex;
    justify-content: center;
    width:100vw;
    height:60vw;    
    overflow: hidden;
}
.scootergif{
    width:100vw;
    position: absolute;
    z-index: 1;
    height:85vw;
    overflow: hidden;
    
    /* padding-right: 5vw; */
    /* margin-left: -5vw; */
    /* background-image: url(../../../../assets/Events_bike\ Animation.gif); */
}
.scooter_gif{
    width: 100vw;
    height:85vw;
    position: absolute;
    transform: scale(2);
    margin-left: -3vw;
    /* transform: translateX(-3vw); */
}
.newtitlewrapp{
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    /* justify-content: center; */
}
.newtitlewrapp h3{
    font-family: Salsa;
    font-size: 3.5vw;
    color:#00522b;
    -webkit-text-stroke: 0.1px white;

}
.newtitlewrapp h1{
    font-family: Boeckline;
    font-size: 15vw;
    color:#2b4a5c;
    -webkit-text-stroke: 0.1px white;
}
.newtitlewrapp button{
    background-color: #2b4a5c;
    font-family: Salsa;
    padding:5px 10px;
    font-size: 2.5vw;
    color:white;
    border: 4px solid white;
    border-radius: 10px;
}
.newallevents{
    width:100vw;
    height:80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index:3;
}
.neweventsmain{
    width:100vw;
    height:50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index:3;
    /* margin-top: 25vw; */
}
.neweventwrapp{
    width:96vw;
    height:50vw;
    border-radius: 10px;
    
    backdrop-filter: blur(5px);
    /* background: rgba(0 0 0 0.5); */
    box-shadow: inset 0 0em 3em rgb(0 0 0 / 30%), 0 0 0 0px white, 0.3em 0.3em 1em rgb(0 0 0 / 60%);
}
/* .neweventwrapp::after{
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    pointer-events: none; 
    filter: drop-shadow(2px 4px 6px black);
} */
.newalltitlediv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:3vw;
    z-index:3;
}
.newallevents h1,.neweventsmain h1{
    font-family: Boeckline;
    font-size: 10vw;
    color:#2b4a5c;
    -webkit-text-stroke: 0.1px white;
}
.textdecimg{
    width:12vw;
}
.newbgevimgmob{
    display: none;
}
@media only screen and (max-width:1200px){
    .newevpgtitle{
        height:50vw;
    }
}
@media only screen and (max-width:768px){
    .newbgevimg{
        display: none;
    }
    .newbgevimgmob{
        display: block;
        width:100vw;
        position:absolute;
        z-index:-1;
    }
    .newtitlewrapp h1{
        font-size: 20vw;
    }
    .newtitlewrapp h3{
        font-size: 6.5vw;
    }
    .newallevents{
        height:auto;
    }
    .neweventsmain{
        height: auto;
    }
    .neweventwrapp{
        height:auto;
    }
}