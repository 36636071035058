.CarouselCommittee {
    height: 90vh;
    width: 100vw;
    margin:-10vh 0;

    /* z-index:-1; */
    /* position: absolute; */
}

.carouselcontentbtn {
    display: flex;
    align-items: center;
}


.moreInfobtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-arrow-mun-car {

    position: relative;
    z-index: 2;
}

.css-1qzevvg {
    margin-left: auto !important;
    margin-top: -10vh !important;
}

.css-1qzevvg img {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
}

.Committeediv {
    /* height: 50vh !important; */
}

@media only screen and (max-width:650px) {
    .CarouselCommittee {
        height: 100vh;
    }
}

@media only screen and (max-width:500px) {
    .CarouselCommittee {
        height: 80vh;
    }

    .carouselcontentbtn {
        bottom: 2vh !important;
    }
}

@media only screen and (max-width:450px) {
    .CarouselCommittee {
        height: 65vh;
        position: relative;
        z-index: 0;
    }
    .carouselcontent{
        margin:5vw;
        position:relative;
    }
}

@media only screen and (max-width:350px) {
    .CarouselCommittee {
        height: 51vh;
    }
}

.carouselcontent {
    position: relative;
}

.carouselcontenttext1 {
    position: absolute;
    bottom: 33vh;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Bangers;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 35.259px */
}

.carouselcontenttext2 {
    position: absolute;
    bottom: 33vh;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Bangers;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 35.259px */
}

.carouselcontenttext3 {
    position: absolute;
    bottom: 33vh;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Bangers;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 35.259px */
}

/* .carouselcontentbtn{
    display: flex;
    align-items: center;
} */
/* .moreInfobtn{
    display: flex;
    align-items: center;
    justify-content: center;
} */

@media only screen and (max-width:768px) {
    .carouselcontenttext1 {
        bottom: 22vh;
    }

    .carouselcontenttext2 {
        bottom: 22vh;
    }

    .carouselcontenttext3 {
        bottom: 37vh;
    }
}



@media only screen and (max-width:650px) {
    .carouselcontenttext {
        bottom: 20vh;
        /* font-size:3vw; */
    }

    /* .carouselcontentbtn{
        height:5vh !important;
    } */

    .carouselcontenttext3 {
        bottom: 30vh;
    }
}

@media only screen and (max-width:500px) {
    .carouselcontenttext {
        bottom: 15vh;
        /* font-size:3vw; */
    }

    /* .carouselcontentbtn{
        height:5vh !important;
    } */
}

@media only screen and (max-width:490px) {
    .carouselcontenttext3 {
        bottom: 25vh;
    }

    .carouselcontentbtn {
        height: 3vh !important;
        bottom: 2vh !important;
    }
}

@media only screen and (max-width:420px) {
    .CommitteeHead{
        margin-top: 0 !important;
    }
    .CarouselCommittee {
        margin-top: 3vh;
    }
    .Committeediv {
        height: 55vh !important;
    }
}
@media only screen and (max-width:400px) {
    .carouselcontenttext3 {
        bottom: 21vh;
    }

    .carouselcontenttext1 {
        bottom: 18vh;
    }

    .carouselcontenttext2 {
        bottom: 19vh;
    }

    .carouselcontentpara {
        margin: auto;
        padding: 0;
        display: inline-block;
        font-size: 2.6vw;
    }
}


@media only screen and (max-width:320px) {
    .carouselcontenttext3 {
        bottom: 17vh;
    }

    .carouselcontenttext1 {
        bottom: 15vh;
    }

    .carouselcontenttext2 {
        bottom: 15vh;
    }
}

@media only screen and (max-width:410px) {
    .carouselcontenttext {
        bottom: 15vh;
        /* font-size:3vw; */
    }

    .carouselcontentbtn {
        height: 2vh !important;
        bottom: 3vh !important;
    }
}

@media only screen and (max-width:330px) {
    .carouselcontentbtn {
        bottom: 2vh !important;
    }
}

.carouselcontentbtn {
    position: absolute;
    bottom: 5vh;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14.024px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    width: 18vw;
    border-radius: 3.506px;
    border: 1.402px solid #F7F7F7;
    display: flex;
    justify-content: center;
    padding: 0.5vh 1vw;
    height: auto;
    /* display: flex;
    align-items: center; */
}

.carouselcontentpara {
    margin: auto;
    padding: 0;
    display: inline-block;
    font-size: 2.6vw;
}

.carouselcontentimg {
    width: 40vw;
}

.MUNcarousel {
    display: flex;
    justify-content: center;
}

.carouselcontentarrow {
    display: inline-block;
}

.infoarrow {
    width: 2vw;
}

/* .infoarrow{
    width:7vw;
} */