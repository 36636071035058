@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.college {
    width: fit-content;
    /* height: fit-content; */
    background:transparent;
  
    border: 2px solid grey;
    border-radius: 8px;
    color:white;
    
    padding: 7vh 5.4vw;
    margin-top: 5vh;
    margin-left: 18vw;
    backdrop-filter: blur(2px);
  }

  #rgs-state-uks{
    width: 25vw;
  }
  #rgs-district-uks{
    width: 25vw;
  }

  .bkimg{
    display: block !important;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* margin-top: -14vh; */
    position:fixed;
    top: 0;
    /* margin-top: -50px; */
  }
  .regNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logButton{
    /* margin-top: 4vh; */
    margin-right: 4vw;
  }
  .regis{
    font-size: 1.5rem;
    font-family: Public Sans;
    padding-bottom: 4vh;
    margin-top: -1vh;
  }
  .mobRegis{
    display: none;
  }

  .registerNow {
    border-radius: 12px;
    border: 2px solid #FFF;
    /* width: 8vw; */
    display: inline-flex;
    background: transparent;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
    color: #FFF;
    cursor: pointer;
font-family: Bangers;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 100%;
letter-spacing: 1.2px;
  }
  .backdropmob{
    display: none;
  }
  
  .college-steps {
    display: flex;
    flex-direction: row;
    gap: 7.28vw;
  }
  
  .college-step1,
  .college-step2 {
    display: flex;
    flex-direction: row;
    gap: 1.39vw;
  }
  
  .college-step1-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1.19px solid #111111;
    border-radius: 5px;
    background-color: #EDD729;
    box-shadow: 1.19px 2.38px 0px 0px #111111;
  }
  
  .college-step1-number-content {
    font-family: Public Sans;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  
  .college-step1-description,
  .college-step2-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .college-step1-description-content-para1,
  .college-step2-description-content-para1 {
    height: 8px;
    color: white;
    font-family: Public Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015rem;
  }
  
  .college-step1-description-content-para2,
  .college-step2-description-content-para2 {
    height: 11px;
    color: white;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
  }
  
  .college-step2-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1.19px solid #111111;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 1.19px 2.38px 0px 0px #111111;
  }
  
  .college-step2-number-content {
    font-family: Public Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
  
    color: #000;
  }
  
  .college-inputs {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    padding: 6vh 0 7vh;
  }
  
  .college-input1,
  .college-input3 {
    display: flex;
    flex-direction: row;
    gap: 3.33vw;
  }
  
  .college-input2 {
    display: flex;
    flex-direction: row;
  }
  
  .input-field {
    box-sizing: border-box;
    /* width: 25.1vw; */
    padding: 12px 20px;
    border-radius: 6px;
    border: 1.5px solid white;
    color: blue;
    background:transparent;
    /* box-shadow: 1.5px 3px 0px 0px #505050; */
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
    height: 40px;
  }
  .input-field::placeholder{
    color: white;
  }
  .input-field::placeholder:hover{
    color: yellow;
  }
  .css-t3ipsp-control{
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .css-1nmdiq5-menu{
    background:white !important;
    /* backdrop-filter: blur(20px) !important; */
    z-index:1 !important;
    color: black;
    font-family: Public Sans;
    /* width:50vw !important; */
    margin-left: -2vw !important;
  }
  .css-19bb58m{
    color: white !important;
  }
  .css-1dimb5e-singleValue{
    color: white !important;
  }
  
  #reg-college-1{
    width: 100%;
  }
  #reg-college-2{
    width: 100%;
  }
  
  .select-field {
    padding: 0 0px;
  }
  
  .input-field:hover,
  .input-field:focus {
    border: 1.5px solid #111111;
    color: #111111;
    box-shadow: 1.5px 3px 0px 0px #111111;
    outline: none;
  }
  
  .input-field:hover::-webkit-input-placeholder {
    color: #111111;
  }
  
  .college-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 2.85vw;
  }
  
  .college-button-clear,
  .college-button-submit {
    padding: 10px 20px;
    border-radius: 6px;
    border: 1.5px;
    font-family: Public Sans;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0em;
    border: 1.52px solid #505050;
    box-shadow: 1.5px 3px 0px 0px #505050;
    cursor: pointer;
  }
  
  .college-button-clear {
    color: #505050;
    background-color: #ffffff;
  }
  
  .college-button-submit {
    color: #ffffff;
    background-color: #cccccc;
  }
  
  .college-button-clear:hover {
    color: #111111;
    border: 1.52px solid #111111;
    box-shadow: 1.5px 3px 0px 0px #111111;
  }
  
  .college-button-submit:hover {
    background-color:yellow;
    border: 1.52px solid #111111;
    box-shadow: 1.5px 3px 0px 0px #111111;
  }

  .widthController{
    width: 40vw;
  }
  

  #check-login{
    display: none;
    justify-content: center;
    margin-top:20px;
    margin-bottom: -10px;
  }
  
  #check-login-1{
    color:black;
  }
  
  #check-login-2{
    color:#FFC414;
  }


  /* //////////////////////// */




  @media screen and (max-width: 930px) {
    .input-field{
      width: 100% !important;
    }
    .th-logo{
      position: relative;
    }
    .college {
      border-radius: 0;
      /* filter: drop-shadow(8px 8px 0px rgba(0, 0, 0, 0.32)); */
      padding: 3.8vh 6.1vw;
      margin-left: 5vh;
      overflow: hidden;
      margin-top: 5vh;
    }
    .bkimg{
        display: none;
    }
    .backdropmob{
    display: block;
    }
  
    .college-step1 {
      gap: 3.33vw;
    }
  
    .college-step2 {
      display: none;
    }
  
    .college-step2-number {
      width: 28px;
      height: 28px;
    }
  
    .personal-step2-number-content {
      font-size: 1rem;
    }
  
    .college-step2-description-content-para1 {
      height: 7px;
      font-size: 0.625rem;
      line-height: 0.75rem;
    }
  
    .college-step2-description-content-para2 {
      height: 9px;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  
    .college-inputs {
      gap: 3.8vh;
      padding: 4.76vh 0 4.52vh;
    }
  
    .college-input1,
    .college-input2,
    .college-input3 {
      flex-direction: column;
      gap: 3.8vh;
    }
  
  
    .college-buttons {
      gap: 3.33vw;
      justify-content: center;
    }
  
    .college-button-clear,
    .college-button-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37.22vw;
      font-size: 1rem;
    }
    #rgs-state-uks{
      width: 77.4vw;
    }
    #rgs-district-uks{
      width: 77.4vw;
    }
  
  }
    @media screen and (max-width: 800px){
    .college{
        margin-left: 5vw;
        margin-top: 5vh;
    }
    .regis{
        display: none;
    }
    .mobRegis{
    display: block;
    font-size: 1.5rem;
    font-family: Public Sans;
    color: #ffffff;
    margin-left: 10vw;
    /* padding-bottom: 4vh; */
    margin-top: 10vh;
   
    }
    #check-login{
      display: flex;
    }
  }
/* @media only screen and (min-width:930px){

  .widthController{
    width:53.5vw;
  }
} */

.css-13cymwt-control{
width:auto;
border:none!important;
min-height:0px !important;
background: transparent !important;
}




@media (min-width:930px){

#enter-college-reg{
  width:53.5vw !important;
}

}
