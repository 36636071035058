@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Comic+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200&family=Roboto:wght@300&display=swap');

.infoBg {
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}

.mun_nav {
    position: absolute;
    z-index: 999;

}

.muninfo_main {
    background-image: url('./munbg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.Bg-image {
    position: absolute;
    width: 100vw;
    height: 100vh;

}

.info_text1 {
    width: 100%;
    padding: 3vh;
    margin-bottom: 3vh;
    margin-top: 3vh;
    /* height: 400px; Adjust as needed */
    background: linear-gradient(to bottom, #061f2c, #0d2f3f);
    /* Gradient colors based on the image */
    border-radius: 15px;
    /* Rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    /* Slight shadow effect */
    width: 50vw;
    margin-right: 5vw;
}

.Mob-Bg-image {
    display: none;
}

.info_box1 {
    position: absolute;
    z-index: 999;
    color: #ffffff;
    margin-top: -2vh;
}

.irmun {
    position: absolute;
    z-index: 999;
    color: #ffffff;
    display: flex;
    font-family: 'Saira', sans-serif;

    font-size: 1vw;
    margin-top: 12vh;
    padding: 0 7vw 0 8vw;
}

.aippm {
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Saira', sans-serif;

    /* font-size: 1vw; */
}

.info_box1 {
    display: flex;
    justify-content: center;
    margin-top: 18vh;
    padding: 0 7vw 0 7vw;
}

.info_heading {
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    font-size: 1.7vw;
    padding: 0 0 5vh 0;
}

.info_content {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.muninfo-text {
    color: #FFF;
    text-align: justify;
    font-family: 'Saira Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
    /* 32.44px */
    letter-spacing: 1px;
    font-size: 1vw;
    padding: 0 0 5vh 0;
    font-family: 'Saira Condensed', sans-serif;
    /* font-size: 21px;
    font-weight: 400;
    line-height: 34.06px;
    letter-spacing: 0.05em;
    text-align: justify; */
}

.mobilemuninfo-text {
    display: none;
}

.info_button {
    font-family: 'Saira', sans-serif;

    font-weight: 400;
    font-size: 3vh;
    color: #ffffff;
    background-color: rgba(217, 217, 217, 0.3);
    border: none;
    padding: 0.5vh 0.5vw 0.5vh 0.5vw;
}

.info_img1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    /* width:40vw; */
    margin: auto;

}

.infoPage1img {
    width: 20vw;
}

.mun-infopageimg1 {
    width: 40vw;
}

.info_img1 h3 {
    width: 25vw;
    color: #FFF;
    text-align: center;
    font-family: Bangers;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 162.2%;
}

@media screen and (max-width: 930px) {
    .muninfo-text {
        font-size: 2vw;
    }
}

@media screen and (max-width: 768px) {
    .info_box1 {
        display: flex;
        flex-direction: column-reverse;
        gap: 2vh;
        margin-top: 20vh;
        z-index: 1;
    }
    .info_text1{
        background: transparent;
        box-shadow: none;
    }

    .muninfo-text {
        display: none;
    }
    .infoPage{
        content: url('../../../../assets/unga.webp');
    }

    .mobilemuninfo-text {
        display: block;
        color: #FFF;
        font-family: Roboto;
        /* font-size: 20px; */
        font-style: normal;
        text-align: justify;
        font-weight: 500;
        line-height: 162.2%;
        /* 32.44px */
        letter-spacing: 1px;
        font-size: 1vh;
        padding: 0 0 5vh 0;
    }

    .infoPage1img {
        width: 100%;
    }

    .info_img1 h3 {
        font-size: 6vw;
        width: 80vw;
    }

    .irmun {
        font-size: 3vw;
        margin-top: 12vh;
        padding: 0 7vw 0 5vw;
    }

    .Mob-Bg-image {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
    }

    .info_heading {
        display: none
    }

    .info_button {
        font-family: Bangers;
        font-weight: 400;
        font-size: 3vh;
        background-color: #FFF;
        border: solid;
        border-color: #0D1E3B;
        border-width: 2px;
        border-radius: 6px;
        padding: 0.5vh 0.5vw 0.5vh 0.5vw;
        color: #0D1E3B;
        line-height: 162.2%;
    }

    .info_text1 {
        margin-top: 1vh;
        width: auto;
        margin-right: 0;
    }

    .info_box1 {
        background: linear-gradient(to bottom, #061f2c, #0d2f3f);
        /* Gradient colors based on the image */
        border-radius: 15px;
        /* Rounded corners */
        margin: auto;
        width: 80%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        /* Slight shadow effect */
    }

    .portfolio_button {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .mobilemuninfo-text {
        font-size: 1vh;
    }


}