.container{
    background-image: url('../../assets/background.png');
    background-size: cover;
    background-position: center;
    height: 100%;
}
.top-section{
    width: 80vw;
    margin: 0 auto;
}
h1{
    
    align-items: center;
    text-align: center;
    color:#155AA1;
    padding:40px;
    font-size: 50px;

}
.info{
    display: flex;
    justify-content: center;
}
.info img{
    width: 230px;
    
    margin: 0 20px;
}
.info .convener_info{ 
   /* background-color: #579AE0 0%,#7DB5F0 19%, #4A92DB 45%);; */
   background: linear-gradient(90deg, #3e85d1 0%, #4582c4 19%, #1778d8 45%);
    width: 54vw;
   opacity: 0.5;
    border-radius: 10px;
    /* margin-left: 10px; */
}
.info .convener_info h2{
    color: white;
    /* font-size: 50px; */
    margin-left: 10px;
    margin-bottom: 0.5vw;
    
}
.info .convener_info h3{
    color: yellow;
    margin-left: 10px;
    margin-top: -15px;
    margin-bottom: 1vw;
}
.info .convener_info p{
    color: white;
    margin-left: 10px;
    margin-top: -15px;
}



.co_convener_section{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.member-section{
    background-image: url('../../assets/member_bg.png');
   height:60vh;
    width: 21vw;
    background-repeat: no-repeat;
    background-position: center;
    flex-wrap: wrap;
    display: flex;
    background-size: 99%;
    gap: 10px;
    position: relative;  

    display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  gap: 10px;
  
}
.member-info{
    position: absolute;
    bottom: 1vw;
    text-align: center;
}
.member-info h2{
    color: #FFD378;
    /* font-size: 15px; */
    /* margin-left: 25px; */
    /* margin-bottom: -20px; */
    text-align: center;


}

.member-info p{
    margin-bottom: 0.5vw;
}

.social-icons{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 2vh;
   /* margin-top: 5vh; */
}

@media screen and (max-width: 1200px){
    .info{
        flex-direction: column;
    }
    .info img{
        width: 150px;
    }
    .info .convener_info h2{
        font-size: 30px;
    }
    .info .convener_info h3{
        font-size: 20px;
    }
    .info .convener_info p{
        font-size: 15px;
    }
    .member-section{
        width: 40vw;
    }
    .member-info h2{
        font-size: 25px;
        /* margin-bottom: -15px; */
    }
    .social-icons{
        /* margin-top: 5vh; */
        margin-bottom: 2vh;
    }
    
}