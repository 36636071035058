@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
.nav-comp {
    display: flex;
    background:transparent;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 3vw; */
    /* padding-right: 4vw; */
    height: 11vh;
    position: relative;
    width: 100vw;
    z-index: 20;
    user-select: none;
}

.event-nav-left {
    width: 90px;
    padding-left: 3vh;
}

.event-nav-right {
    display: flex;
    gap: 3vw;
    height: 100%;
    align-items: center;
    padding-right: 4vh;
}

@media only screen and (max-width:980px) and (min-width:768px){
    .event-nav-right-compo{
        font-size: 15px !important;
    }
}

.event-nav-right-compo {
    color: var(--default-2, #FFF);
    text-align: justify;
    leading-trim: both;
    text-edge: cap;
    font-family: Salsa;
    font-size: 20px;
    font-style: normal;
    cursor: default;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    padding: 8px;
}

.event-nav-right-compo:hover {
    cursor: pointer;
    /* text-decoration: underline; */
}



/* .event-nav-right-compo {
    text-decoration: underline 0.15em rgba(255, 255, 255, 0);
    transition: text-decoration-color 300ms;
  }
  
.event-nav-right-compo:hover {
    text-decoration-color: rgba(255, 255, 255, 1);
  }  */

  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: 
  #ffffff;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: 
  #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

/* .event-nav-right-compo::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f7b900;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.event-nav-right-compo:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
} */

.colhover:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

.event-regi-btn {
    color: #000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Salsa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
    text-decoration: none;
    border-radius: 8px;
    background: #F7B900;

    display: inline-flex;

    justify-content: center;
    align-items: center;
    gap: 10px;
    /* margin-right: 4vw; */
    padding: 5px 20px;
}
.event-regi-btn:hover{
    /* color:black;
    background:#b98b04;
    transition: 0.3s ease-in-out;
    /* border-color: #fff; */
}


.drop-row {
    width: 20vw;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.drop {
    position: absolute;
    top: 8vh;
    right: 10vw;
    height: auto;
    z-index: 11;
    display: flex;
    padding: 0 10px 30px 10px;
    justify-content: space-evenly;
    width: 80vw;
    background-color: #282828;
}

.drop-row .col {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Salsa;
    font-size: .8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    margin-left: 40px;

}

.col:hover {
    text-decoration: underline;
}

.col-yellow {
    color: #F7B900 !important;
    margin-top: 25px !important;
    margin-bottom: 10px;
    cursor: default !important;
}

.col-yellow:hover {
    text-decoration: none !important;
}

.col-yellow1 {
    color: #F7B900 !important;

}

.drop2 {
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-comp1 {
    display: none;
}

.mainnav {
    width: 100%;
    display: contents;
}

#img_profile {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.none_display{
    display: none;
}

.l_body{
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    z-index: 1;
    left: 0px;
    /* width: 100vw; */
    /* height: 100vh; */
    background: rgba(10, 14, 17, 0.8);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:768px) {
    .mainnav {
        display: none;
    }

    .nav-comp1 {
        display: flex;
        width: 100vw;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
    }

    .nav-comp {
        background: transparent;
    }

    .logo-width {
        width: 90px;
    }

    .logo2 {
        /* width: 40px;
        height: 30px; */
        height: 50px;
        width: 50px;
    }


    .slidebar1 h1 {

        color: #FFF;
        text-align: justify;
        leading-trim: both;
        text-edge: cap;
        font-family: Salsa;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 30px 0;
    }

    .slidebar1 h4 {

        color: #FFF;
        text-align: justify;
        leading-trim: both;
        text-edge: cap;
        font-family: Salsa;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 30px 0;
        margin-top: 13vh;
    }

    .slidebar1 h3 {

        color: #FFF;
        text-align: justify;
        leading-trim: both;
        text-edge: cap;
        font-family: Salsa;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .slidebar1 span {
        color: #F7B900;
        text-align: justify;
        leading-trim: both;
        text-edge: cap;
        font-family: Salsa;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 20px 0;
        transition: .3s ease-in;
    }

    .slidebar1 h3 {
        color: #FFF;
        text-align: justify;
        leading-trim: both;
        text-edge: cap;
        font-family: Salsa;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px 0;
    }

    .bgmobile {
        display: block !important;
        position: absolute;
        z-index: 0;
        /* heig ht: 100vh; */
        width: 100vw;
    }

    .nav-comp {
        position: relative;
    }

    .ECD-container {
        position: relative;

    }
    .slidebar1 h4:hover{
        text-decoration: underline;
        cursor: pointer;
    }
    .slidebar1 h1:hover{
        text-decoration: underline;
        cursor: pointer;
    }
    .slidebar1 h5:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.bgmobile {
    display: none;
}

.inner_mob_nav {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    z-index: 100;
    justify-content: space-between;
    padding: 0 10px;
}

@keyframes slide {
    0% {
        right: -100vw;
    }

    100% {
        right: 0vw;
        background-color: black;
        background-size: cover;
        background-position: center center;
    }
}

@keyframes slide1 {
    0% {
        right: 0vw;
        background-color: black;
    }

    100% {
        right: -100vw;
        background-color: black;
        background-size: cover;
        background-position: center center;
    }
}

.slidebar1 {
    position: fixed;
    top: 0vh;
    right: 0vw;
    width: 100vw;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slide .6s forwards;
    padding-bottom: 20px;
    min-height: 108vh;
}

.slidebar2 {
    position: fixed;
    top: 0vh;
    right: -100vw;
    width: 100vw;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slide1 .6s;
    padding-bottom: 20px;
    min-height: 108vh;
}

.slidebar1 h5 {
    color: #FFF;
    text-align: justify;
    leading-trim: both;
    text-edge: cap;
    font-family: Salsa;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 30px 0;
}

.mob-reg {
    /* margin-top: auto; */
    margin-bottom: 200px;
    display: inline-flex;
    padding: 8px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #ff3535;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Salsa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
}

a {
    text-decoration: none;
}

.logo3 {
    width: 40px;
    height: 30px;
}