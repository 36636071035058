.munfooterwrapper {
    /* padding-left: 20vw; */
    width: 80%;
    /* background: linear-gradient(180deg, #429EFF 0%, #D3E9FF 42%); */
    height: 100%;
    padding-bottom: 4vw;
    position: relative;
    bottom: 0;
    /* margin-top: 62vw; */
    margin-left: auto;
    margin-right: auto;
    transition: transform 1s ease-in-out;
    /* z-index: -10; */
    padding-top: 20vw;
}

.logo-munfooter>img {
    width: 23vw;
}

.number {
    margin-top: 4vw;
}

.dxnmunfooter {
}

.munfooterhead {
    margin-top: 7vw;
    color: #284551;
    font-family: Salsa;
    font-size: 4vw;
    font-weight: 600;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.munfootercontent {
    color: #284551;
    font-family: Salsa;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: 2vw;
}
