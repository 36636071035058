@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.CarouselCommittee
{
    height: 85vh;
    width: 100vw;
    margin-top:-6vh;
}
.judge1-img{
    /* height:58vh; */
    width:52vw;
    object-fit: cover;
    border-radius: 17.796px;
    border: 5px solid #fdd52e;
}
.judge2-img{
    /* height:58vh; */
    width:52vw;
    border-radius: 17.796px;
    border: 5px solid #fdd52e;
    
}
.judge3-img{
    /* height:58vh; */
    width: 52vw;
    border-radius: 17.796px;
    border: 5px solid #fdd52e;
}
.judge1-discription{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;


}
.judge1-discription1{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.judge1-discription2{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.judge1-discription3{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
/* .j1{
    object-fit: cover;
} */
/* .hero1{
    object-fit: cover;
} */

@media only screen and (max-width:650px)
{
    .CarouselCommittee
{
    height: 100vh;
}
}
@media only screen and (max-width:500px)
{
    .CarouselCommittee
{
    margin-top: 5vh;
    height: 60vh;
}
}
@media only screen and (max-width:450px)
{
    .CarouselCommittee
{
    height: 60vh;
}

    .ExecutiveMobile{
        margin-top: -5vh;
    }

.judge1-img{
    height:48vh;
    border-radius: 17.796px;
    width: auto;
    
}

.judge2-img{
    height:48vh;
    border-radius: 17.796px;
    width: auto;
}
.judge3-img{
    height:48vh;
    border-radius: 17.796px;
    width: auto;
}
}
@media only screen and (max-width:350px)
{
    .CarouselCommittee
{
    height: 51vh;
}
/* .hero1{
    object-fit: cover;
} */
.judge1-img{
    height:40vh;
    width: auto;
    border-radius: 17.796px;
}
.judge2-img{
    height:40vh;
    border-radius: 17.796px;
    width: auto;
}
.judge3-img{
    height:40vh;
    border-radius: 17.796px;
    width: auto;
}
}