.nnp-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* background: url(../../../assests/profile1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
}

.pro-back-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.boxborder {
  position: absolute;
  width: 90vw;
  height: 80vh;
  top: 15vh;
  left: 5vw;
  /* border: 2px solid rgba(42, 42, 42, 0.98); */
}

.nnp-content {
  display: flex;
  flex-direction: row;
  gap: 3vw;
  margin-top: -50px;
}

.nnp-mobile {
  display: none;
}

.nnp-mobhead {
  display: none;
}

.pro-back-img2 {
  display: none;
}

.eventbgpic1{
  margin:auto;
  width:20vw;
}

@media only screen and (max-width: 768px) {
  .nnp-content {
    display: none;
  }

  .boxborder {
    height: auto;
  }

  .boxborder::-webkit-scrollbar {
    display: none;
  }

  .nnp-mobile {
    display: contents;
  }

  .boxborder {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 88vw;
  }

  .nnp-laphead {
    display: none;
  }

  .pro-back-img2 {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    display: block;
  }

  .nnp-mobhead {
    display: contents;
  }

  .nnp-head {
    position: relative;
  }

  .hamburg {
    background-color: transparent;
  }

  .main-prof-box {
    width: 67vw;
    height: 80vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
  }

  .eventbgpic1{
    margin:auto;
    width:50vw;
  }
  
}
.event-space{
  padding-top: 100px !important;
 
}
.leftPos {
  z-index: 3;
  height: 110vh;
}

.paymentcenterimg {
  margin: auto;
}

.mobpaymentcenterimg {
  padding-bottom: 1vh;
  display: flex;
  height: 81vh;
  overflow-y: hidden;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  backdrop-filter: blur(2px);
  border:2px solid #555;
  border-radius: 5px;
}
.mobpaymentcenterimg::-webkit-scrollbar{
  display: none !important;
}
@media only screen and (max-width: 350px) {
  .mobpaymentcenterimg {
    width: 88vw !important;
    /* height: 30vh; */
  }
}

.paymentmobpage {
  display: none;
}

.event-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;

  gap: 30px;
}

.event-box button {
  width: 180px;
  color: #333;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 100% */
  letter-spacing: -0.32px;
  display: inline-flex;
  padding: 9px 8px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  background: #FFF;
}
